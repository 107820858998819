import Configuration from "../../../configuration";
import { useParams } from "react-router-dom";
import { getFileNews, newsGetById } from "../../../Redux/newsReduce";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Card, Container, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

function DetailNew({ onlineStatus, obj }) {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const t = useTranslation();

  const [titre, setTitre] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [fileURL, setFileURL] = React.useState(null);

  var idNeW = location.id;

  const getNewDetail = React.useCallback(
    async (idNeW) => {
      dispatch(getFileNews(idNeW)).then(async (e1) => {
        var ff = null;
        ff = new Blob([e1.payload], {
          type: "application/*",
        });
        const fileURL = await URL.createObjectURL(ff);
        setFileURL(fileURL);
      });
      dispatch(newsGetById(idNeW)).then(async (e2) => {
        var res = e2.payload;
        setTitre(res.titre);
        setDescription(res.description);
        setDate(res.date);
        setFileName(res.file);
      });
    },
    [dispatch]
  );
  useEffect(() => {
    getNewDetail(idNeW);
  }, []);

  return (
    <>
      <Container fluid>
        <div className="section-image section-news">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="return"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="success"
                  onClick={() => {
                    navigate.push("/listNews");
                  }}
                >
                  <span className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4" className="">
                          {t("news.detail_news")}
                        </Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12" className="marginbtn-10">
                          <b> {t("news.title")}</b> : {titre}
                        </Col>
                        <Col md="12" className="marginbtn-10">
                          <b> {t("news.description")}</b> : {description}
                        </Col>
                        <Col md="12" className="marginbtn-10">
                          <b> {t("news.date")}</b> : {date}
                        </Col>
                      </Row>
                      <div className="visualiser">
                        <img
                          width="100%"
                          src={Configuration.BACK_BASEURL + "new/" + fileName}
                          /* src={fileURL} */
                        />
                        <br></br>
                      </div>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailNew;
