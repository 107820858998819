import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
/* import Button from "@mui/material/Button"; */
import Typography from "@mui/material/Typography";
// react component used to create alerts
// react-bootstrap components
import Donnes from "./Donnes";
import Patient from "./Patient";
import Effets from "./Effets";
import Medicament from "./Medicament";
import { useEffect, useCallback } from "react";
import { fetchAge } from "../../Redux/ageReduce";
import { getActiveIndication } from "../../Redux/indicationReduce";
import { getActiveVoix } from "../../Redux/voix_administrationReduce";
import { declarationAdded } from "../../Redux/declarationReduce";
import { getPaysByMed } from "../../Redux/paysReduce";
import { fetchAction } from "../../Redux/actionReduce";
import { useDispatch } from "react-redux";
import validator from "validator";
import { toast, ToastContainer } from "react-toastify";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-multi-lang";
import { openDB } from "idb";
import {
  loginFetch,
  verificationEmail,
  verification,
} from "../../Redux/usersReduce";
import { getRootByRole } from "../../Redux/rootBaseReduce";
import SweetAlert from "react-bootstrap-sweetalert";
import useOfflineStatus from "../../Offline";
import { fetchForme } from "../../Redux/formeReduce";
import { fetchCategorieAge } from "../../Redux/categorieAgeReduce";

function Declaration({ obj, onlineStatus }) {
  let db;

  var stateLine = useOfflineStatus();

  async function initUser() {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("detailUser", "readwrite");
    let store = tx.objectStore("detailUser");
    let detail = await store.getAll();
    if (detail.length !== 0) setUsersOff(detail[0]);
  }

  const t = useTranslation();
  const [alert, setAlert] = React.useState(null);
  /** start init local storage **/
  let nomStorage = window.localStorage.getItem("nom");
  let prenomStorage = window.localStorage.getItem("prenom");
  let emailStorage = window.localStorage.getItem("email");
  let telStorage = window.localStorage.getItem("tel");
  let passwordStorage = window.localStorage.getItem("password");
  let typeSpecialiteStorage = window.localStorage.getItem("typeSpecialite");
  let idSpecialiteStorage = window.localStorage.getItem("idSpecialite");
  let nomSpecialiteStorage = window.localStorage.getItem("nomSpecialite");
  let idFormeStorage = window.localStorage.getItem("idForme");
  let nomFormeStorage = window.localStorage.getItem("forme");
  let idCategoryAgeStorage = window.localStorage.getItem("idCategoryAge");
  let nomCategoryAgeStorage = window.localStorage.getItem("categoryAge");

  var objSp = nomSpecialiteStorage
    ? {
        value: idSpecialiteStorage,
        label: nomSpecialiteStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  var objfm = nomFormeStorage
    ? {
        value: idFormeStorage,
        label: nomFormeStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  var objCategAge = nomCategoryAgeStorage
    ? {
        value: idCategoryAgeStorage,
        label: nomCategoryAgeStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  let autreSpStorage = window.localStorage.getItem("autreSp");
  let initialesStorage = window.localStorage.getItem("initiales");
  let ageStorage = window.localStorage.getItem("age");
  let sexeStorage = window.localStorage.getItem("sexe");
  let dateNaissanceStorage = window.localStorage.getItem("dateNaissance");
  let agePatientStorage = window.localStorage.getItem("agePatient");
  let poidStorage = window.localStorage.getItem("poid");
  let tailleStorage = window.localStorage.getItem("taille");
  let allergieStorage = window.localStorage.getItem("allergie");
  let ageCatStorage = window.localStorage.getItem("ageCat");
  let idAgeCatStorage = window.localStorage.getItem("idAgeCat");
  var objAge = ageCatStorage
    ? {
        value: idAgeCatStorage,
        label: ageCatStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };
  let indicationStorage = window.localStorage.getItem("indication");
  let idIndicationStorage = window.localStorage.getItem("idIndication");
  var objInd = indicationStorage
    ? {
        value: idIndicationStorage,
        label: indicationStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  let action = window.localStorage.getItem("action");
  let idAction = window.localStorage.getItem("idAction");
  var objAction = action
    ? {
        value: idAction,
        label: action,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  let payss = window.localStorage.getItem("pays");
  let idPays = window.localStorage.getItem("idPays");
  var objPays = payss
    ? {
        value: idPays,
        label: payss,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };

  let dateDebutStorage = window.localStorage.getItem("dateDebut");
  let dateFinStorage = window.localStorage.getItem("dateFin");
  let informationStorage = window.localStorage.getItem("information");
  let complementairesStorage = window.localStorage.getItem("complementaires");
  let description_effStorage = window.localStorage.getItem("description_eff");
  let graveStorage = window.localStorage.getItem("grave");
  let hospitalisationStorage = window.localStorage.getItem("hospitalisation");
  let pronosticStorage = window.localStorage.getItem("pronostic");
  let incapaciteStorage = window.localStorage.getItem("incapacite");
  let anomalieStorage = window.localStorage.getItem("anomalie");
  let decesStorage = window.localStorage.getItem("deces");
  let autreStorage = window.localStorage.getItem("autre");
  let evolutionStorage = window.localStorage.getItem("evolution");
  let traitesStorage = window.localStorage.getItem("traites");
  let survenusStorage = window.localStorage.getItem("survenus");
  let medicamentStorage = window.localStorage.getItem("medicament");
  let idMedicamentStorage = window.localStorage.getItem("idMedicament");
  var objMed = medicamentStorage
    ? {
        value: idMedicamentStorage,
        label: medicamentStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };
  let dateDebutAdminStorage = window.localStorage.getItem("dateDebutAdmin");
  let dateFinAdminStorage = window.localStorage.getItem("dateFinAdmin");
  let numeroStorage = window.localStorage.getItem("numero");
  let posologieStorage = window.localStorage.getItem("posologie");
  let date_adminStorage = window.localStorage.getItem("date_admin");
  let therapeutiqueStorage = window.localStorage.getItem("therapeutique");
  let voixStorage = window.localStorage.getItem("voix");
  let idVoixStorage = window.localStorage.getItem("idVoix");
  var objVoix = voixStorage
    ? {
        value: idVoixStorage,
        label: voixStorage,
      }
    : {
        value: 0,
        label: t("select"),
        isDisabled: true,
      };
  let effetStorage = window.localStorage.getItem("effet");
  /** end init local storage **/
  var lang = localStorage.getItem("lang");
  var icon1 =
    lang === "ar" ? "fas fa-angle-double-left" : "fas fa-angle-double-right";
  var icon2 =
    lang === "ar" ? "fas fa-angle-double-right" : "fas fa-angle-double-left";
  var token = localStorage.getItem("x-access-token");
  var nom_prenom = "";
  if (token !== null) {
    var decoded = jwt_decode(token);
    if (stateLine === false) {
      var id = decoded.id;
    } else {
      var id = obj.idUser;
    }
  }
  const dispatch = useDispatch();
  const navigate = useHistory();
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const steps = [
    t("Declaration.data"),
    t("Declaration.patient"),
    t("Declaration.drugs"),
    t("Declaration.effects"),
  ];
  const [entities, setEntities] = React.useState([]);
  const [entitiesOff, setEntitiesOff] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  //Donnes
  const [nom, setNom] = React.useState(nomStorage ? nomStorage : "");
  const [prenom, setPrenom] = React.useState(
    prenomStorage ? prenomStorage : ""
  );
  const [tel, setTel] = React.useState(telStorage ? parseInt(telStorage) : "");
  const [email, setEmail] = React.useState(emailStorage ? emailStorage : "");
  const [password, setPassword] = React.useState(
    passwordStorage ? passwordStorage : ""
  );
  const [specialite, setSpecialite] = React.useState(objSp);
  const [typeSpecialite, setTypeSpecialite] = React.useState(
    typeSpecialiteStorage ? parseInt(typeSpecialiteStorage) : 0
  );
  const [idSpecialite, setIdSpecialite] = React.useState(
    idSpecialiteStorage ? parseInt(idSpecialiteStorage) : 0
  );
  const [autreSp, setAutreSp] = React.useState(
    autreSpStorage ? parseInt(autreSpStorage) : ""
  );
  //Patient
  const [initiales, setInitiales] = React.useState(
    initialesStorage ? initialesStorage : ""
  );
  const [age, setAge] = React.useState(ageStorage ? parseInt(ageStorage) : "");
  const [sexe, setSexe] = React.useState(
    sexeStorage ? parseInt(sexeStorage) : ""
  );
  const [dateNaissance, setDateNaissance] = React.useState(
    dateNaissanceStorage ? dateNaissanceStorage : ""
  );
  const [agePatient, setAgePatient] = React.useState(
    agePatientStorage ? agePatientStorage : ""
  );
  const [poid, setPoid] = React.useState(poidStorage ? poidStorage : "");
  const [taille, setTaille] = React.useState(
    tailleStorage ? tailleStorage : ""
  );
  const [allergie, setAllergie] = React.useState(
    allergieStorage ? allergieStorage : ""
  );
  const [ageCategorie, setAgeCategorie] = React.useState(objAge);

  const [optionsPays, setOptionsPays] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [pays, setPays] = React.useState(objPays);
  const [forme, setForme] = React.useState(objfm);

  // action
  const [optionsActions, setOptionsActions] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [actions, setActions] = React.useState(objAction);

  const [optionsAge, setOptionsAge] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [indication, setIndication] = React.useState(objInd);
  const [optionsIndication, setOptionsIndication] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);

  //Effets
  const [dateDebut, setDateDebut] = React.useState(
    dateDebutStorage ? dateDebutStorage : ""
  );
  const [dateFin, setDateFin] = React.useState(
    dateFinStorage ? dateFinStorage : ""
  );
  const [information, setInformation] = React.useState(
    informationStorage ? informationStorage : ""
  );
  const [complementaires, setComplementaires] = React.useState(
    complementairesStorage ? complementairesStorage : ""
  );
  const [description_eff, setDescription_eff] = React.useState(
    description_effStorage ? description_effStorage : ""
  );
  const [grave, setGrave] = React.useState(
    graveStorage ? parseInt(graveStorage) : 0
  );
  const [hospitalisation, setHospitalisation] = React.useState(
    hospitalisationStorage ? parseInt(hospitalisationStorage) : 0
  );
  const [pronostic, setPronostic] = React.useState(
    pronosticStorage ? parseInt(pronosticStorage) : 0
  );
  const [incapacite, setIncapacite] = React.useState(
    incapaciteStorage ? parseInt(incapaciteStorage) : 0
  );
  const [anomalie, setAnomalie] = React.useState(
    anomalieStorage ? parseInt(anomalieStorage) : 0
  );
  const [deces, setDeces] = React.useState(
    decesStorage ? parseInt(decesStorage) : 0
  );
  const [autre, setAutre] = React.useState(
    autreStorage ? parseInt(autreStorage) : 0
  );
  const [evolution, setEvolution] = React.useState(
    evolutionStorage ? parseInt(evolutionStorage) : 0
  );
  const [traites, setTraites] = React.useState(
    traitesStorage ? parseInt(traitesStorage) : 0
  );
  const [survenus, setSurvenus] = React.useState(
    survenusStorage ? parseInt(survenusStorage) : ""
  );
  //Medicament
  const [medicament, setMedicament] = React.useState(objMed);
  const [optionsMedicament, setOptionsMedicament] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [dateDebutAdmin, setDateDebutAdmin] = React.useState(
    dateDebutAdminStorage ? dateDebutAdminStorage : ""
  );
  const [dateFinAdmin, setDateFinAdmin] = React.useState(
    dateFinAdminStorage ? dateFinAdminStorage : ""
  );
  const [numero, setNumero] = React.useState(
    numeroStorage ? numeroStorage : ""
  );
  const [posologie, setPosologie] = React.useState(
    posologieStorage ? posologieStorage : ""
  );
  const [date_admin, setDate_admin] = React.useState(
    date_adminStorage ? date_adminStorage : ""
  );
  const [therapeutique, setTherapeutique] = React.useState(
    therapeutiqueStorage ? therapeutiqueStorage : ""
  );
  const [voix, setVoix] = React.useState(objVoix);
  const [optionsVoix, setOptionsVoix] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [optionsEffet, setOptionsEffet] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);
  const [optionsFormes, setOptionsFormes] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);

  const [categoryAge, setCategoryAge] = React.useState(objCategAge);
  const [optionsCategoryAge, setOptionsCategoryAge] = React.useState([
    {
      value: 0,
      label: t("select"),
      isDisabled: true,
    },
  ]);

  const [effet, setEffet] = React.useState(effetStorage ? effetStorage : "");
  const [dataUri, setDataUri] = React.useState("");
  const [file, setFile] = React.useState(t("Declaration.file"));
  const [color, setColor] = React.useState("initial");
  const [users, setUsers] = React.useState(null);
  const [usersOff, setUsersOff] = React.useState(null);

  const [nomPrenom, setNomPrenom] = React.useState();

  /* const [effet, setEffet] = React.useState({
    value: 0,
    label: t("select"),
    isDisabled: true,
  }); */

  var token = localStorage.getItem("x-access-token");

  async function initRoot() {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("rootBase", "readwrite");
    const index = tx.store.index("parent");
    let store = tx.objectStore("rootBase");
    let rootArray = await store.getAll();
    var arrayView = [];
    for (const key in rootArray) {
      var e = rootArray[key];
      if (e.parent !== 0) {
        var rolesFils = e.role;
        var splitRoleFils = rolesFils.split(",");
        var arrayRoleFils = [];
        splitRoleFils.forEach((element) => {
          arrayRoleFils.push(parseInt(element));
        });
        arrayView.push({
          className: e.className,
          path: "/" + e.path,
          name: e.name,
          icon: e.icon,
          role: arrayRoleFils,
          componentStr: e.component,
          parent: e.parent,
        });
        /* rootFils.push(element) */
      }
    }
    var arrayParent = [];
    for await (const cursor of index.iterate(0)) {
      var objRole = { ...cursor.value };
      const pos = arrayView.map((e) => e.parent).indexOf(objRole.id);

      var rolesFils = objRole.role;
      var splitRoleFils = rolesFils.split(",");
      var arrayRole = [];
      splitRoleFils.forEach((element) => {
        arrayRole.push(parseInt(element));
      });
      if (pos === -1) {
        var obj = {
          path: "/" + objRole.path,
          className: objRole.className,
          name: objRole.name,
          icon: objRole.icon,
          role: arrayRole,
          componentStr: objRole.component,
          parent: objRole.parent,
        };
        arrayParent.push(obj);
      } else {
        var obj = {
          collapse: true,
          path: "/" + objRole.path,
          name: objRole.name,
          state: "pere" + objRole.id,
          className: objRole.className,
          icon: objRole.icon,
          role: arrayRole,
          views: arrayView,
        };
        arrayParent.push(obj);
      }
    }
    setEntitiesOff(arrayParent);
  }

  /** start Age **/
  const getAges = useCallback(async () => {
    var entities = [];
    if (stateLine == false) {
      var res = await dispatch(fetchAge());
      entities = res.payload;
    } else {
      //get age from indexDb
      var tx = db.transaction("ages", "readwrite");
      let store = tx.objectStore("ages");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description =
        lang === "fr"
          ? e.description
          : lang === "en"
          ? e.description_en
          : e.description_ar;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsAge(arrayOption);
  }, [dispatch]);

  const getRoot = useCallback(
    async (user) => {
      if (user) {
        dispatch(getRootByRole(user.user.id_role)).then((val) => {
          if (val.payload.status) {
            initRoot();
          } else {
            setEntities(val.payload);
          }
        });
        // saveData(user);
      }
    },
    [dispatch]
  );

  const verifToken = useCallback(
    async (res) => {
      var response = await dispatch(verification());
      if (response.payload === false) {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        getRoot(res);
      }
    },
    [dispatch, getRoot]
  );

  /** end Age **/

  /** start Indication **/
  const getIndication = useCallback(async () => {
    if (stateLine == false) {
      //get indications from db mysql
      var res = await dispatch(getActiveIndication());
      var entities = res.payload;
    } else {
      //get indications from indexDb
      var tx = db.transaction("indications", "readwrite");
      let store = tx.objectStore("indications");
      var entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description =
        lang === "fr"
          ? e.description
          : lang === "en"
          ? e.description_en
          : e.description_ar;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsIndication(arrayOption);
  }, [dispatch]);
  /** end Indication **/

  // actions
  const getActions = useCallback(async () => {
    var entities = [];
    if (stateLine == false) {
      //get pays from db mysql
      var res = await dispatch(fetchAction());
      entities = res.payload;
    } else {
      //get pays from indexDb
      var tx = db.transaction("actions", "readwrite");
      let store = tx.objectStore("actions");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description =
        lang === "fr" ? e.nom_fr : lang === "en" ? e.nom_en : e.nom_ar;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsActions(arrayOption);
  }, [dispatch]);
  //

  /** start paysMed **/
  const getPays = useCallback(async () => {
    var entities = [];
    if (stateLine == false) {
      //get pays from db mysql
      var res = await dispatch(getPaysByMed());
      entities = res.payload;
    } else {
      //get paysMed from indexDb
      var tx = db.transaction("paysMed", "readwrite");
      let store = tx.objectStore("paysMed");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description = e.nom_en;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsPays(arrayOption);
  }, [dispatch]);
  /** end pays **/

  /** start voix_administrations **/
  const getVoix = useCallback(async () => {
    var entities = [];
    if (stateLine === false) {
      //get voix_administrations from db mysql
      var res = await dispatch(getActiveVoix());
      var entities = res.payload;
    } else {
      //get voix_administrations from indexDb
      var tx = db.transaction("voix_administrations", "readwrite");
      let store = tx.objectStore("voix_administrations");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description =
        lang === "fr"
          ? e.description
          : lang === "en"
          ? e.description_en
          : e.description_ar;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsVoix(arrayOption);
  }, [dispatch]);
  /** end voix_administrations **/

  /** start categorie age **/
  const getCategoryAge = useCallback(async () => {
    var entities = [];
    if (stateLine === false) {
      //get voix_administrations from db mysql
      var res = await dispatch(fetchCategorieAge());
      var entities = res.payload;
    } else {
      //get voix_administrations from indexDb
      var tx = db.transaction("categoriesAges", "readwrite");
      let store = tx.objectStore("categoriesAges");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var description =
        lang === "fr"
          ? e.description
          : lang === "en"
          ? e.description_en
          : e.description_ar;
      arrayOption.push({ value: e.id, label: description });
    });
    setOptionsCategoryAge(arrayOption);
  }, [dispatch]);
  /** end categorie age **/

  /** start formes **/
  const getForme = useCallback(async () => {
    var entities = [];
    if (stateLine === false) {
      //get voix_administrations from db mysql
      var res = await dispatch(fetchForme());
      var entities = res.payload;
    } else {
      //get voix_administrations from indexDb
      var tx = db.transaction("formes", "readwrite");
      let store = tx.objectStore("formes");
      entities = await store.getAll();
    }
    var arrayOption = [];
    entities.forEach((e) => {
      var nom = lang === "fr" ? e.nom : lang === "en" ? e.nom_en : e.nom_ar;
      arrayOption.push({ value: e.id, label: nom });
    });
    setOptionsFormes(arrayOption);
  }, [dispatch]);
  /** end formes **/

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = (activeStep) => {
    var newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  //storeDetailUser
  //get detailUser from indexDb
  async function storeDetailUser(email) {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("detailUser", "readwrite");
    const index = tx.store.index("email");
    for await (let cursor of index.iterate(email)) {
      var objDetail = { ...cursor.value };
      localStorage.setItem("x-access-token", objDetail.token);
    }
    await tx.done;
    window.location.replace("/profile");
  }

  //save detailUser in indexDb
  async function initDetailUser(login, password) {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("detailUser", "readwrite");
    await tx.objectStore("detailUser").clear();
    await tx.objectStore("detailUser").add({
      id: 0,
      email: login,
      password: password,
      nom: "",
      prenom: "",
      id_role: "",
      etat: "",
      token: "",
      code: "",
    });
    window.location.replace("/profile");
  }

  function connect(id, e) {
    var login = localStorage.getItem("email");
    var password = localStorage.getItem("password");
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        var users = await dispatch(
          loginFetch({ login: login, password: password })
        );
        var entities = users.payload;
        resolve(entities);
      }, 0);
    });

    promise.then((value) => {
      if (value) {
        if (value.status !== 200) {
          notify("1", "Probléme de connexion");
        } else {
          localStorage.clear();
          localStorage.setItem("x-access-token", value.data.token);
          initDetailUser(login, password);
        }
      } else {
        storeDetailUser(login);
      }
    });
    /* hideAlert();
    localStorage.clear();
    window.location.replace("/login"); */
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmMessage = () => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Vous voulez vous connecter? Ce email existe déjà"}
        onConfirm={() => connect()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("login")}
        cancelBtnText={t("close")}
        showCancel
      >
        <Row>
          {/* <Col md="12">
            
          </Col> */}
          <Col md="12">
            <Form.Group>
              <Form.Control
                placeholder={t("User.email")}
                type="text"
                onChange={(value) => {
                  localStorage.setItem("email", value.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <br></br>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Control
                placeholder={t("User.password")}
                onChange={(value) => {
                  localStorage.setItem("password", value.target.value);
                }}
                type="password"
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </SweetAlert>
    );
  };

  const verifEmail = useCallback(
    async (email) => {
      if (stateLine === false) {
        var verif = await dispatch(verificationEmail({ email }));
        var res = verif.payload;
        if (res) confirmMessage();
      }
    },
    [dispatch]
  );

  /* const handleComplete = () => { */
  //navigation entre les component
  const handleComplete = useCallback(
    async (
      nom,
      prenom,
      email,
      tel,
      idSpecialite,
      activeStep,
      initiales,
      age,
      ageCategorie,
      agePatient,
      sexe,
      dateNaissance,
      indication,
      poid,
      taille,
      allergie,
      pays,
      medicament,
      numero,
      posologie,
      voix,
      dateDebutAdmin,
      dateFinAdmin,
      date_admin,
      therapeutique,
      description_eff,
      actions,
      stateLine,
      dateDebut,
      dateFin,
      grave,
      hospitalisation,
      pronostic,
      deces,
      incapacite,
      anomalie,
      autre,
      traites,
      evolution,
      survenus,
      information,
      complementaires,
      forme,
      categoryAge,
      effet,
      dataUri
    ) => {
      var test = true;
      var id_sp = idSpecialite;
      var id_indication = indication.value;
      var id_pays = pays.value;
      var id_medicament = medicament.value;
      var id_voix = voix.value;
      var ageCat = ageCategorie.value;
      var idCategoryAge = categoryAge.value;
      var agePatient = agePatient;
      var idAction = actions.value;
      var idForme = forme.value;

      if (activeStep == 0) {
        if (
          validator.isEmpty(nom) ||
          validator.isEmpty(prenom) ||
          validator.isEmpty(email) ||
          id_sp === 0
        ) {
          notify(2, t("erreur"));
          test = false;
        } else {
          test = false;

          if (stateLine === false) {
            var verif = await dispatch(verificationEmail({ email }));
            var res = verif.payload;
            if (res) {
              confirmMessage();
            } else {
              test = true;
            }
          } else {
            test = true;
          }
          // else{
          //   test = true;
          // }

          /* dispatch(verificationEmail({ email })).then((verif) => {
          var res = verif.payload;
          if (res) {
            test = false;
            confirmMessage();
          }
          console.log(test)
        }); */
        }
      } else if (activeStep == 1) {
        var testPatient = true;
        var testAge = true;
        var diff = Date.now() - new Date(dateNaissance).getTime();
        var ageDate = new Date(diff);
        var resAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        var id_initials = document.querySelector("#id_initials .form-control");
        id_initials.style.borderColor = validator.isEmpty(initiales)
          ? "red"
          : "#ccc";
        if (age === "" && validator.isEmpty(initiales) && sexe === "") {
          setColor("colorRed");
          testPatient = false;
        } else {
          setColor("initial");
        }
        var msg = "";
        if (testPatient === true) {
          if (age == 0) {
            testAge = false;
            msg = t("Declaration.erreur");
          } else if (age === 3 && ageCategorie.value === 0) {
            testAge = false;
            msg = t("Declaration.erreurCategorieAge");
          } else if (age == 0 || (age === 1 && dateNaissance === "")) {
            msg = t("Declaration.erreurDateNaissance");
            testAge = false;
          } else if (
            age == 0 ||
            (age === 1 && dateNaissance !== "" && resAge <= 1)
          ) {
            msg = t("Declaration.erreurDateNaissance");
            testAge = false;
          } else if (
            age == 0 ||
            (age === 2 && agePatient === "") ||
            (age === 2 && idCategoryAge == 0)
          ) {
            msg = t("Declaration.erreurAge");
            testAge = false;
          } else if (
            age == 0 ||
            (age === 2 && agePatient !== "" && agePatient <= 1)
          ) {
            msg = t("Declaration.erreurAge");
            testAge = false;
          }
        }

        if (!testPatient) {
          notify(2, t("Declaration.erreur"));
          test = false;
        } else if (!testAge) {
          notify(2, msg);
          test = false;
        }
      } else if (activeStep == 2) {
        var id_drugs = document.querySelector(
          "#id_drugs .react-select__control"
        );
        id_drugs.style.borderColor = id_medicament === 0 ? "red" : "#ccc";

        var id_country = document.querySelector(
          "#id_country .react-select__control"
        );
        id_country.style.borderColor = id_pays === 0 ? "red" : "#ccc";

        var id_forme = document.querySelector(
          "#id_forme .react-select__control"
        );
        id_forme.style.borderColor = idForme == 0 ? "red" : "#ccc";

        var posologieInput = document.querySelector(
          "#posologie_form .form-control"
        );
        posologieInput.style.borderColor = posologie == 0 ? "red" : "#ccc";

        if (
          id_medicament === 0 ||
          (posologie > 0 && idForme == 0) ||
          (posologie == 0 && idForme > 0)
        ) {
          notify(2, t("erreur"));
          test = false;
        }
      } else if (activeStep == 3) {
        var id_description_eff = document.querySelector(
          "#id_description_eff .form-control"
        );
        id_description_eff.style.borderColor = validator.isEmpty(
          description_eff
        )
          ? "red"
          : "#ccc";
        if (validator.isEmpty(description_eff) || survenus == 0) {
          notify(2, t("erreur"));
          test = false;
        } else {
          var tokenL = localStorage.getItem("x-access-token");
          var anneeL = localStorage.getItem("annee");
          let langL = window.localStorage.getItem("lang");

          if (stateLine === false) {
            dispatch(
              declarationAdded({
                id_user: id,
                nom: nom,
                prenom: prenom,
                tel: tel,
                email: email,
                id_sp: id_sp,
                initiales: initiales,
                age: age,
                sexe: sexe,
                dateNaissance: dateNaissance,
                agePatient: agePatient,
                ageCategorie: ageCat,
                id_indication: id_indication,
                img: dataUri,
                /* id_eff: id_eff, */
                effet: effet,
                dateDebut: dateDebut,
                dateFin: dateFin,
                information: information,
                complementaires: complementaires,
                id_medicament: id_medicament,
                dateDebutAdmin: dateDebutAdmin,
                dateFinAdmin: dateFinAdmin,
                id_voix: id_voix,
                numero: numero,
                posologie: posologie,
                grave: grave,
                hospitalisation: hospitalisation,
                pronostic: pronostic,
                incapacite: incapacite,
                anomalie: anomalie,
                autre: autre,
                evolution: evolution,
                traites: traites,
                survenus: survenus,
                deces: deces,
                date_admin: date_admin,
                therapeutique: therapeutique,
                description_eff: description_eff,
                poid: poid,
                taille: taille,
                allergie: allergie,
                lang: lang,
                idAction: idAction,
                idForme: idForme,
                idCategoryAge: idCategoryAge,
              })
            ).then(() => {
              notify(1, t("declaration_send"));
              setTimeout(async () => {
                localStorage.clear();
                if (tokenL) localStorage.setItem("x-access-token", tokenL);
                if (anneeL) localStorage.setItem("annee", anneeL);
                localStorage.setItem("lang", langL);
                if (token === null) {
                  navigate.push("/login");
                } else {
                  navigate.push("/listDeclaration");
                }
              }, 1500);
            });
          } else {
            saveDeclarationIndex(
              nom,
              prenom,
              email,
              idSpecialite,
              tel,
              id,
              obj,
              initiales,
              age,
              ageCategorie,
              agePatient,
              sexe,
              dateNaissance,
              indication,
              poid,
              taille,
              allergie,
              medicament,
              numero,
              posologie,
              voix,
              dateDebutAdmin,
              dateFinAdmin,
              date_admin,
              therapeutique,
              description_eff,
              actions,
              dateDebut,
              dateFin,
              grave,
              hospitalisation,
              pronostic,
              deces,
              incapacite,
              anomalie,
              autre,
              traites,
              evolution,
              survenus,
              information,
              complementaires,
              forme,
              categoryAge
            );
          }
        }
      }
      if (test === true) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        if (activeStep < 3) handleNext(activeStep);
      }
    },
    [dispatch]
  );

  //cette function pour sauvgarder dans indexDb si user non conecter à son wifi
  async function saveDeclarationIndex(
    nom,
    prenom,
    email,
    idSpecialite,
    tel,
    id,
    obj,
    initiales,
    age,
    ageCategorie,
    agePatient,
    sexe,
    dateNaissance,
    indication,
    poid,
    taille,
    allergie,
    medicament,
    numero,
    posologie,
    voix,
    dateDebutAdmin,
    dateFinAdmin,
    date_admin,
    therapeutique,
    description_eff,
    actions,
    dateDebut,
    dateFin,
    grave,
    hospitalisation,
    pronostic,
    deces,
    incapacite,
    anomalie,
    autre,
    traites,
    evolution,
    survenus,
    information,
    complementaires,
    forme,
    categoryAge
  ) {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("declarations", "readwrite");
    let store = tx.objectStore("declarations");
    let declarations = await store.getAll();
    var date1 = new Date(); // Or the date you'd like converted.
    var date = new Date(date1.getTime() - date1.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10);
    var idNewDeclaration =
      declarations.length === 0
        ? 1
        : declarations[declarations.length - 1].id + 1;

    try {
      const result = await tx.objectStore("declarations").add({
        id: idNewDeclaration,
        id_user: obj !== undefined ? obj.idUser : null,
        users: obj !== undefined ? obj : null,
        img: dataUri,
        passagers: {
          nom: nom,
          prenom: prenom,
          email: email,
          tel: tel,
          id_sp: idSpecialite,
        },
        patients: {
          img: dataUri,
          createdAt: date,
          id: idNewDeclaration,
          id_user: id !== undefined ? id : null,
          users: obj !== undefined ? obj : null,
          initiales: initiales,
          age: age,
          sexe: sexe,
          dateNaissance: dateNaissance,
          agePatient: agePatient,
          ageCategorie: ageCategorie.value,
          idCategoryAge: categoryAge.value,
          ages:
            ageCategorie.value > 0
              ? {
                  id: ageCategorie.value,
                  description: ageCategorie.label,
                  description_ar: ageCategorie.label,
                  description_en: ageCategorie.label,
                }
              : null,

          categorie_ages: categoryAge
            ? {
                id: categoryAge.value,
                description: categoryAge.label,
                description_ar: categoryAge.label,
                description_en: categoryAge.label,
              }
            : null,

          id_indication: indication.value != 0 ? indication.value : null,
          indications: {
            id: indication.value,
            description: indication.label,
            description_ar: indication.label,
            description_en: indication.label,
          },

          poid: poid,
          taille: taille,
          allergie: allergie,
        },
        effet: effet,
        dateDebut: dateDebut,
        dateFin: dateFin,
        information: information,
        complementaires: complementaires,
        id_medicament: medicament.value,
        medicaments: {
          id: medicament.value,
          nom: medicament.label,
          nom_ar: medicament.label,
          nom_en: medicament.label,
        },
        dateDebutAdmin: dateDebutAdmin,
        dateFinAdmin: dateFinAdmin,
        id_voix: voix.value != 0 ? voix.value : null,
        voix_administrations: {
          id: voix.value,
          description: voix.label,
          description_ar: voix.label,
          description_en: voix.label,
        },
        action: actions
          ? {
              id: actions.value,
              nom_fr: actions.label,
              nom_ar: actions.label,
              nom_en: actions.label,
            }
          : null,
        id_forme: forme.value != 0 ? forme.value : null,
        formes: {
          id: forme.value,
          nom: forme.label,
          nom_ar: forme.label,
          nom_en: forme.label,
        },
        numero: numero,
        posologie: posologie,
        grave: grave,
        hospitalisation: hospitalisation,
        pronostic: pronostic,
        incapacite: incapacite,
        anomalie: anomalie,
        autre: autre,
        evolution: evolution,
        traites: traites,
        survenus: survenus,
        deces: deces,
        date_admin: date_admin,
        therapeutique: therapeutique,
        description_eff: description_eff,
        lang: lang,
        type_table: 8,
        saved: 0,
      });
    } catch (error) {
      console.log(error);
    }

    notify(1, t("declaration_send"));

    setTimeout(async () => {
      if (token === null) {
        navigate.push("/login");
      } else {
        navigate.push("/listDeclaration");
      }
    }, 1500);
  }

  //open DB medis
  async function init() {
    db = await openDB("medis", 1, {});
    getIndication();
    getVoix();
    getAges();
    getPays();
    getActions();
    getForme();
    getCategoryAge();
  }

  useEffect(() => {
    if (token !== null) {
      var decoded = jwt_decode(token);
      if (obj.user != undefined)
        setNomPrenom(obj.user.nom + " " + obj.user.prenom);
      else setNomPrenom(obj.nom + " " + obj.prenom);
    }
    let element = document.getElementById("declaration-id");
    // removing the class
    if (element) element.className = "";
    init();
    if (token !== null) {
      var newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext(0);
    }
  }, [getAges, getIndication, getVoix, nomPrenom, stateLine]);

  const onClick = () => {
    if (token === null) {
      navigate.push("/login");
    } else {
      navigate.push("/listDeclaration");
    }
  };

  const handleBack = () => {
    var newCompleted = completed;
    delete newCompleted[activeStep - 1];
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <ToastContainer />
      {alert}

      <div className={lang === "ar" ? "dec-ar declaration" : "declaration"}>
        <Row>
          <Col md="6" sm="6" xs="6">
            <img src={require("../../assets/img/logo.png")} alt="medicacom" />
          </Col>
          <Col md="6" sm="6" xs="6">
            <div className="flag">
              <img
                src={require("../../assets/img/en.png")}
                alt="en"
                onClick={(e) => {
                  window.localStorage.setItem("lang", "en");
                  window.location.reload();
                }}
              />
              <img
                src={require("../../assets/img/fr.png")}
                alt="fr"
                onClick={(e) => {
                  window.localStorage.setItem("lang", "fr");
                  window.location.reload();
                }}
              />
              <img
                src={require("../../assets/img/ar.png")}
                alt="ar"
                onClick={(e) => {
                  window.localStorage.setItem("lang", "ar");
                  window.location.reload();
                }}
              />
            </div>
            <Button
              className={
                lang === "ar"
                  ? "btn-fill float-left btn-declaration"
                  : "btn-fill float-right btn-declaration"
              }
              type="button"
              variant="success"
              onClick={onClick}
            >
              {token !== null ? nomPrenom : t("sign_in")}
            </Button>
          </Col>
        </Row>
        <Box sx={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                {activeStep === 0 ? (
                  <Donnes
                    nom={nom}
                    setNom={setNom}
                    prenom={prenom}
                    setPrenom={setPrenom}
                    tel={tel}
                    setTel={setTel}
                    email={email}
                    setEmail={setEmail}
                    specialite={specialite}
                    setSpecialite={setSpecialite}
                    password={password}
                    setPassword={setPassword}
                    idSpecialite={idSpecialite}
                    setIdSpecialite={setIdSpecialite}
                    typeSpecialite={typeSpecialite}
                    setTypeSpecialite={setTypeSpecialite}
                    autreSp={autreSp}
                    setAutreSp={setAutreSp}
                    stateLine={stateLine}
                  ></Donnes>
                ) : activeStep === 1 ? (
                  <Patient
                    initiales={initiales}
                    setInitiales={setInitiales}
                    age={age}
                    setAge={setAge}
                    sexe={sexe}
                    setSexe={setSexe}
                    dateNaissance={dateNaissance}
                    setDateNaissance={setDateNaissance}
                    agePatient={agePatient}
                    setAgePatient={setAgePatient}
                    optionsAge={optionsAge}
                    ageCategorie={ageCategorie}
                    setAgeCategorie={setAgeCategorie}
                    optionsIndication={optionsIndication}
                    indication={indication}
                    setIndication={setIndication}
                    poid={poid}
                    setPoid={setPoid}
                    taille={taille}
                    setTaille={setTaille}
                    allergie={allergie}
                    setAllergie={setAllergie}
                    optionsCategoryAge={optionsCategoryAge}
                    categoryAge={categoryAge}
                    setCategoryAge={setCategoryAge}
                  ></Patient>
                ) : activeStep === 2 ? (
                  <Medicament
                    optionsMedicament={optionsMedicament}
                    setOptionsMedicament={setOptionsMedicament}
                    stateLine={stateLine}
                    medicament={medicament}
                    setMedicament={setMedicament}
                    dateDebutAdmin={dateDebutAdmin}
                    setDateDebutAdmin={setDateDebutAdmin}
                    dateFinAdmin={dateFinAdmin}
                    setDateFinAdmin={setDateFinAdmin}
                    optionsVoix={optionsVoix}
                    optionsFormes={optionsFormes}
                    voix={voix}
                    setVoix={setVoix}
                    numero={numero}
                    setNumero={setNumero}
                    posologie={posologie}
                    setPosologie={setPosologie}
                    date_admin={date_admin}
                    setDate_admin={setDate_admin}
                    therapeutique={therapeutique}
                    setTherapeutique={setTherapeutique}
                    optionsEffet={optionsEffet}
                    effet={effet}
                    setEffet={setEffet}
                    optionsPays={optionsPays}
                    pays={pays}
                    setPays={setPays}
                    forme={forme}
                    setForme={setForme}
                    optionsActions={optionsActions}
                    actions={actions}
                    setActions={setActions}
                  ></Medicament>
                ) : (
                  <Effets
                    description_eff={description_eff}
                    setDescription_eff={setDescription_eff}
                    dateDebut={dateDebut}
                    setDateDebut={setDateDebut}
                    dateFin={dateFin}
                    setDateFin={setDateFin}
                    information={information}
                    setInformation={setInformation}
                    complementaires={complementaires}
                    setComplementaires={setComplementaires}
                    grave={grave}
                    setGrave={setGrave}
                    hospitalisation={hospitalisation}
                    setHospitalisation={setHospitalisation}
                    pronostic={pronostic}
                    setPronostic={setPronostic}
                    incapacite={incapacite}
                    setIncapacite={setIncapacite}
                    anomalie={anomalie}
                    setAnomalie={setAnomalie}
                    autre={autre}
                    setAutre={setAutre}
                    evolution={evolution}
                    setEvolution={setEvolution}
                    traites={traites}
                    setTraites={setTraites}
                    survenus={survenus}
                    setSurvenus={setSurvenus}
                    deces={deces}
                    setDeces={setDeces}
                    dataUri={dataUri}
                    setDataUri={setDataUri}
                    file={file}
                    setFile={setFile}
                    color={color}
                  ></Effets>
                )}
              </Typography>
            </React.Fragment>
          </div>
        </Box>
        <Row>
          <Col md="6" sm="6" xs="6">
            <div className="handleBack">
              {activeStep !== 0 ? (
                <Button
                  disabled={activeStep === 1 && token !== null ? true : false}
                  className="btn-fill"
                  type="button"
                  variant="success"
                  onClick={handleBack}
                >
                  <i class={icon2}></i>
                  {t("back")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col md="6" sm="6" xs="6">
            <div className="submit-dec">
              <Button
                className="btn-fill"
                type="button"
                variant="success"
                onClick={() =>
                  handleComplete(
                    nom,
                    prenom,
                    email,
                    tel,
                    idSpecialite,
                    activeStep,
                    initiales,
                    age,
                    ageCategorie,
                    agePatient,
                    sexe,
                    dateNaissance,
                    indication,
                    poid,
                    taille,
                    allergie,
                    pays,
                    medicament,
                    numero,
                    posologie,
                    voix,
                    dateDebutAdmin,
                    dateFinAdmin,
                    date_admin,
                    therapeutique,
                    description_eff,
                    actions,
                    stateLine,
                    dateDebut,
                    dateFin,
                    grave,
                    hospitalisation,
                    pronostic,
                    deces,
                    incapacite,
                    anomalie,
                    autre,
                    traites,
                    evolution,
                    survenus,
                    information,
                    complementaires,
                    forme,
                    categoryAge,
                    effet,
                    dataUri
                  )
                }
              >
                {activeStep === 3 ? t("envoyer") : t("next")}
                <i class={activeStep < 3 ? icon1 : ""}></i>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Declaration;
