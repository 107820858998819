import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { fetchAnnee, deleteAnnee } from "../../../Redux/anneeReduce";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import { useMemo } from "react";
import { useTranslation } from "react-multi-lang";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_AR } from "../../utils/ar_table";
import { openDB } from "idb";

// core components
function ListAnnee({ onlineStatus }) {
  document.title = "Liste des annees";
  const dispatch = useDispatch();
  const navigate = useHistory();
  const t = useTranslation();
  let db;
  const [entities, setEntities] = React.useState([]);
  let lang = window.localStorage.getItem("lang");
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: t("year"),
        accessorKey: "annee",
      },
      {
        accessorKey: 'id',
        header: t("actions"),
        Cell: ({ cell, row }) => 
          onlineStatus === false ? (
            <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push("/annee/update/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              onClick={() => {
                deleteMessage(cell.row.original.id);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i className="fa fa-trash" />
            </Button>
          </div>
        ) : (
          ""
        )
      },
      //end
    ],
    [],
  );
  const [alert, setAlert] = React.useState(null);
  function ajouter() {
    navigate.push("/ajouterAnnee");
  }

  //storeAnnee
  const storeAnnee = useCallback(async (res) => {
    const tx = db.transaction("annees", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("annees").add({
        id: res[index].id,
        annee: res[index].annee,
        selected: res[index].selected,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 6,
      });
    }
  }, []);

  async function clearAnnee(res) {
    let tx = db.transaction("annees", "readwrite");
    await tx.objectStore("annees").clear();
    if (res.length != 0) storeAnnee(res);
  }

  const getAnnee = useCallback(async () => {
    var indication = await dispatch(fetchAnnee());
    var res = await indication.payload;
    setEntities(res);
    clearAnnee(res);
  }, [dispatch]);

  async function initAnnee() {
    const tx = db.transaction("annees", "readwrite");
    let anneeStore = tx.objectStore("annees");
    let annee = await anneeStore.getAll();
    setEntities(annee);
  }
  async function init() {
    db = await openDB("medis", 1, {});
    if (onlineStatus === false) getAnnee();
    else {
      initAnnee();
    }
  }

  useEffect(() => {
    init();
  }, []); 
  const deleteMessage = useCallback(
    async (id) => {
      setAlert(
        <SweetAlert
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title={t("supprimerAnnee")}
          onConfirm={() => deleteAnnees(id)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui"
          cancelBtnText="Non"
        ></SweetAlert>
      );
    },
    [dispatch]
  );
  const hideAlert = () => {
    setAlert(null);
  };
  function deleteAnnees(id) {
    dispatch(deleteAnnee(id)).then((e) => {
      if (e.payload === true) {
        notify(1, t("supprime_txt"));
        getAnnee();
        hideAlert();
      } else {
        notify(2, "Vérifier vos données");
      }
    });
  }
  
  function ListTable({list}){
    return (<MaterialReactTable
      columns={columns}
      data={list}
      enableColumnActions={true}
      enableColumnFilters={true}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      muiTableBodyRowProps={{ hover: false }}
      localization={
        lang === "fr"
          ? MRT_Localization_FR
          : lang === "ar"
          ? MRT_Localization_AR
          : MRT_Localization_EN
      }
    /> )
  }
  return (
    <>
      {alert}
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Button
              id="saveBL"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="success"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
             {t("annee.add")}
            </Button>
          </Col>
          <Col md="12">
          <h4 className="title">{t("annee.list")}</h4>
            <Card>
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListAnnee;
