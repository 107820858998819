import React, { useState, useEffect, useCallback } from "react";
import { loginFetch } from "../../../Redux/usersReduce";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Col,
  Tab,
  Nav,
  Row,
} from "react-bootstrap";
import { openDB } from "idb/with-async-ittr";
import Inscription from "./Inscription";
import { useTranslation } from "react-multi-lang";
import { fetchIndication } from "../../../Redux/indicationReduce";
import { getActiveSpecialite } from "../../../Redux/specialiteReduce";
import { fetchAge } from "../../../Redux/ageReduce";
import { fetchMedicament } from "../../../Redux/medicamentReduce";
import { getPaysByMed } from "../../../Redux/paysReduce";
import { fetchVoix_administration } from "../../../Redux/voix_administrationReduce";
import { fetchAction } from "../../../Redux/actionReduce";
import { fetchUsers } from "../../../Redux/usersReduce";
import { fetchDeclaration } from "../../../Redux/declarationReduce";
import { fetchNews } from "../../../Redux/newsReduce";
import { fetchMarcheIms } from "../../../Redux/marcheImsReduce";
import { fetchGouvernorat } from "../../../Redux/gouvernoratReduce";
import { getPersonnel } from "../../../Redux/usersReduce";
import { fetchNotifications } from "../../../Redux/notificationReduce";
import { fetchPays } from "../../../Redux/paysReduce";
import { fetchRootBase } from "../../../Redux/rootBaseReduce";
import useOfflineStatus from "../../../Offline";
import onLine from "../../../VerifOffline";
import { fetchForme } from "../../../Redux/formeReduce";
import { fetchCategorieAge } from "../../../Redux/categorieAgeReduce";
import { fetchAnnee } from "../../../Redux/anneeReduce";
function LoginPage() {
  const t = useTranslation();
  let lang = window.localStorage.getItem("lang");
  var ar = lang === "ar" ? " login-ar" : "";
  var stateLine = useOfflineStatus();

  var onLineStat = onLine();
  //console.log(onLineStat,'onLineStat')

  const navigate = useHistory();

  document.title = "I-DECLARE";
  let db;
  const notifyErr = (msg) =>
    toast.error(
      <strong>
        <i className="fas fa-exclamation-circle"></i>
        {msg}
      </strong>
    );
  const dispatch = useDispatch();
  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [login, setLogin] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
    if (stateLine == false) {
      clearIndications();
      clearSpecialite();
      clearAge();
      clearPays();
      clearVoix();
      clearMedicament();
      clearUser();
      clearNews();
      clearDeclaration();
      clearMarcheims();
      clearGouvernorats();
      clearPersonels();
      clearNotification();
      clearPaysMed();
      clearRoots();
      clearActions();
      clearForms();
      clearCategorieAge();
      clearAnnee();
    }
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
    };
  }, []);
  function loginChange(event) {
    setLogin(event.target.value);
  }
  function passwordChange(event) {
    setPassword(event.target.value);
  }
  function enterKeyPressed(event) {
    if (event.charCode === 13) {
      submitForm();
      return true;
    } else {
      return false;
    }
  }

  // add notification
  const storeNotifications = useCallback(async () => {
    var notif = await dispatch(fetchNotifications());
    var res = await notif.payload;
    const tx = db.transaction("notifications", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("notifications").add({
        id: res[index].id,
        id_user: res[index].id_user,
        lu: res[index].lu,
        text: res[index].text,
        text_ar: res[index].text_ar,
        text_en: res[index].text_en,
        etat: res[index].etat,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 10,
      });
    }
  }, []);

  //clear data notifications from indexDB
  async function clearNotification() {
    db = await openDB("medis", 1, {});
    let txt = db.transaction("notifications", "readwrite");

    await txt.objectStore("notifications").clear();
    storeNotifications();
  }

  //storeGouvernorat
  //save data gouvernorats in indexDB
  const storeGouvernorat = useCallback(async () => {
    var gouvernorats = await dispatch(fetchGouvernorat());
    var resGouv = await gouvernorats.payload;
    const tx = db.transaction("gouvernorats", "readwrite");
    for (let index = 0; index < resGouv.length; index++) {
      await tx.objectStore("gouvernorats").add({
        libelle: resGouv[index].libelle,
        id: resGouv[index].id,
      });
    }
  }, [dispatch]);

  //clear data gouvernorats from indexDB
  async function clearGouvernorats() {
    db = await openDB("medis", 1, {});
    let txGouv = db.transaction("gouvernorats", "readwrite");
    await txGouv.objectStore("gouvernorats").clear();
    storeGouvernorat();
  }

  //storePersonel
  //save data personnel in indexDB
  const storePersonel = useCallback(async () => {
    var personal = await dispatch(getPersonnel());
    var res = personal.payload.findValider;
    const tx = db.transaction("personels", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("personels").add({
        id: res[index].id,
        nom: res[index].nom,
        prenom: res[index].prenom,
        email: res[index].email,
        id_role: res[index].id_role,
        tel: res[index].tel,
        nom_role: res[index].roles.nom,
        etat: res[index].etat,
        password: res[index].password,
        token: res[index].token,
        code: res[index].code,
        saved: 1,
        type_table: 3,
        updated: 0,
        valider: res[index].valider,
        id_gouvernorat: res[index].id_role,
        nom_gouvernorat:
          res[index].gouvernorats.libelle +
          "@@" +
          res[index].gouvernorats.libelle_en +
          "@@" +
          res[index].gouvernorats.libelle_ar,
        id_sp: res[index].id_role,
        nom_sp: res[index].specialites
          ? res[index].specialites.nom +
            "@@" +
            res[index].specialites.nom_en +
            "@@" +
            res[index].specialites.nom_ar
          : null,
        autre_sp: res[index].autre_sp,
      });
    }
  }, [dispatch]);

  //clear data personels from indexDB
  async function clearPersonels() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("personels", "readwrite");
    await tx.objectStore("personels").clear();
    storePersonel();
  }

  //storeRoots
  //save data rootBase in indexDB
  const storeRoots = useCallback(async () => {
    var root = await dispatch(fetchRootBase());
    var resRoots = await root.payload;
    const tx = db.transaction("rootBase", "readwrite");
    for (let index = 0; index < resRoots.length; index++) {
      await tx.objectStore("rootBase").add({
        id: resRoots[index].id,
        name: resRoots[index].name,
        name_en: resRoots[index].name_en,
        name_ar: resRoots[index].name_ar,
        className: resRoots[index].className,
        path: resRoots[index].path,
        component: resRoots[index].component,
        icon: resRoots[index].icon,
        role: resRoots[index].role,
        ordre: resRoots[index].ordre,
        parent: resRoots[index].parent,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 1,
      });
    }
  }, [dispatch]);

  //clear data rootBase from indexDB
  async function clearRoots() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("rootBase", "readwrite");
    await tx.objectStore("rootBase").clear();
    storeRoots();
  }

  const storeDeclarations = useCallback(async () => {
    var response = await dispatch(fetchDeclaration());
    var res = await response.payload;
    const tx = db.transaction("declarations", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("declarations").add({
        patients: res[index].patients,
        id_patient: res[index].patients.id,
        initiales: res[index].patients.initiales,
        age: res[index].patients.age,
        sexe: res[index].patients.sexe,
        dateNaissance: res[index].patients.dateNaissance,
        agePatient: res[index].patients.agePatient,
        ageCategorie: res[index].patients.ageCategorie,
        id_indication: res[index].patients.id_indication,
        id_passager: res[index].patients.id_passager,
        poid: res[index].patients.poid,
        taille: res[index].patients.taille,
        allergie: res[index].patients.allergie,
        dateDebut: res[index].dateDebut,
        dateFin: res[index].dateFin,
        information: res[index].information,
        complementaires: res[index].complementaires,
        posologie: res[index].posologie,
        numero: res[index].numero,
        dateDebutAdmin: res[index].dateDebutAdmin,
        dateFinAdmin: res[index].dateFinAdmin,
        id_medicament: res[index].id_medicament,
        id: res[index].id,
        id_user: res[index].id_user,
        users: res[index].users,
        nom_user: res[index].nom + " " + res[index].prenom,
        id_eff: res[index].id_eff,
        id_voix: res[index].id_voix,
        id_patient: res[index].id_patient,
        id_passager: res[index].id_passager,
        passagers: res[index].passagers,
        description_eff: res[index].description_eff,
        grave: res[index].grave,
        hospitalisation: res[index].hospitalisation,
        pronostic: res[index].pronostic,
        incapacite: res[index].incapacite,
        deces: res[index].deces,
        anomalie: res[index].anomalie,
        autre: res[index].autre,
        traites: res[index].traites,
        evolution: res[index].evolution,
        survenus: res[index].survenus,
        date_admin: res[index].date_admin,
        therapeutique: res[index].therapeutique,
        createdAt: res[index].patients.createdAt,
        createdAt: res[index].patients.updatedAt,
        id_passager: res[index].patients.id_passager,
        medicaments: res[index].medicaments,
        effet_indesirables: res[index].effet_indesirables,
        voix_administrations: res[index].voix_administrations,
      });
    }
  }, [dispatch]);

  //clear data declarations from indexDB
  async function clearDeclaration() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("declarations", "readwrite");
    /// await tx.objectStore("declarations").clear();
    storeDeclarations();
  }

  /// save indication
  const storeIndication = useCallback(async () => {
    var age = await dispatch(fetchIndication());
    var res = await age.payload;
    const tx = db.transaction("indications", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("indications").add({
        description: res[index].description,
        description_en: res[index].description_en,
        description_ar: res[index].description_ar,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data indications from indexDB
  async function clearIndications() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("indications", "readwrite");
    await tx.objectStore("indications").clear();
    storeIndication();
  }

  /// save indication
  const storeFormes = useCallback(async () => {
    var age = await dispatch(fetchForme());
    var res = await age.payload;
    const tx = db.transaction("formes", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("formes").add({
        nom: res[index].nom,
        nom_en: res[index].nom_en,
        nom_ar: res[index].nom_ar,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data formes from indexDB
  async function clearForms() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("formes", "readwrite");
    await tx.objectStore("formes").clear();
    storeFormes();
  }

  // add specialite
  const storeSpecialite = useCallback(async () => {
    var spec = await dispatch(getActiveSpecialite());
    var res = await spec.payload;
    const tx = db.transaction("specialites", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("specialites").add({
        nom: res[index].nom,
        id: res[index].id,
      });
    }
  }, [dispatch]);

  //clear data specialites from indexDB
  async function clearSpecialite() {
    db = await openDB("medis", 1, {});
    let txtSpec = db.transaction("specialites", "readwrite");
    await txtSpec.objectStore("specialites").clear();
    storeSpecialite();
  }

  // add age
  const storeAge = useCallback(async () => {
    var age = await dispatch(fetchAge());
    var res = await age.payload;
    const tx = db.transaction("ages", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("ages").add({
        description: res[index].description,
        description_en: res[index].description_en,
        description_ar: res[index].description_ar,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data ages from indexDB
  async function clearAge() {
    db = await openDB("medis", 1, {});
    let txMedicament = db.transaction("ages", "readwrite");
    await txMedicament.objectStore("ages").clear();
    storeAge();
  }

  // add age
  const storeCategorieAge = useCallback(async () => {
    var age = await dispatch(fetchCategorieAge());
    var res = await age.payload;
    const tx = db.transaction("categoriesAges", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("categoriesAges").add({
        description: res[index].description,
        description_en: res[index].description_en,
        description_ar: res[index].description_ar,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data ages from indexDB
  async function clearCategorieAge() {
    db = await openDB("medis", 1, {});
    let txMedicament = db.transaction("categoriesAges", "readwrite");
    await txMedicament.objectStore("categoriesAges").clear();
    storeCategorieAge();
  }

  //save data news in indexDB
  const storeNews = useCallback(async () => {
    var response = await dispatch(fetchNews());
    var resNews = await response.payload;
    const tx = db.transaction("news", "readwrite");
    for (let index = 0; index < resNews.length; index++) {
      await tx.objectStore("news").add({
        titre: resNews[index].titre,
        description: resNews[index].description,
        date: resNews[index].date,
        etat: resNews[index].etat,
        id: resNews[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 4,
      });
    }
  }, []);
  //clear data news from indexDB
  async function clearNews() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("news", "readwrite");
    await tx.objectStore("news").clear();
    storeNews();
  }

  // add Medicament
  const storeMedicament = useCallback(async () => {
    var age = await dispatch(fetchMedicament());
    var res = await age.payload;
    const tx = db.transaction("medicaments", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("medicaments").add({
        nom: res[index].nom,
        nom_en: res[index].nom_en,
        nom_ar: res[index].nom_ar,
        etat: res[index].etat,
        dosage: res[index].dosage,
        presentation: res[index].presentation,
        id: res[index].id,
        id_pays: res[index].id_pays,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data medicaments from indexDB
  async function clearMedicament() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("medicaments", "readwrite");
    await tx.objectStore("medicaments").clear();
    storeMedicament();
  }

  // add pays
  const storePays = useCallback(async () => {
    var pays = await dispatch(fetchPays());
    var res = await pays.payload;
    const tx = db.transaction("pays", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("pays").add({
        code: res[index].code,
        nom: res[index].nom,
        nom_en: res[index].nom_en,
        alpha2: res[index].alpha2,
        alpha3: res[index].alpha3,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data pays from indexDB
  async function clearPays() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("pays", "readwrite");
    await tx.objectStore("pays").clear();
    storePays();
  }

  //save data paysMed in indexDB
  const storePaysMed = useCallback(async () => {
    var age = await dispatch(getPaysByMed());
    var res = await age.payload;
    const tx = db.transaction("paysMed", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("paysMed").add({
        code: res[index].code,
        nom: res[index].nom,
        nom_en: res[index].nom_en,
        alpha2: res[index].alpha2,
        alpha3: res[index].alpha3,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);
  //clear data paysMed from indexDB
  async function clearPaysMed() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("paysMed", "readwrite");
    await tx.objectStore("paysMed").clear();
    storePaysMed();
  }

  // add actions
  const storeActions = useCallback(async () => {
    var actions = await dispatch(fetchAction());
    var res = await actions.payload;
    const tx = db.transaction("actions", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("actions").add({
        id: res[index].id,
        nom_fr: res[index].nom_fr,
        nom_en: res[index].nom_en,
        nom_ar: res[index].nom_ar,
      });
    }
  }, []);

  //clear data actions from indexDB
  async function clearActions() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("actions", "readwrite");
    await tx.objectStore("actions").clear();
    storeActions();
  }

  // add annee
  const storeAnnee = useCallback(async () => {
    var actions = await dispatch(fetchAnnee());
    var res = await actions.payload;
    const tx = db.transaction("annees", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("annees").add({
        id: res[index].id,
        annee: res[index].annee,
        selected: res[index].selected,
      });
    }
  }, []);

  //clear data annee from indexDB
  async function clearAnnee() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("annees", "readwrite");
    await tx.objectStore("annees").clear();
    storeAnnee();
  }

  // add actions
  const storeMarcheIms = useCallback(async () => {
    var ims = await dispatch(fetchMarcheIms());
    var res = await ims.payload;
    const tx = db.transaction("marcheims", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("marcheims").add({
        lib: res[index].lib,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data marcheims from indexDB
  async function clearMarcheims() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("marcheims", "readwrite");
    await tx.objectStore("marcheims").clear();
    storeMarcheIms();
  }

  const storeVoix = useCallback(async () => {
    var age = await dispatch(fetchVoix_administration());
    var res = await age.payload;
    const tx = db.transaction("voix_administrations", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("voix_administrations").add({
        description: res[index].description,
        description_en: res[index].description_en,
        description_ar: res[index].description_ar,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  //clear data voix_administrations from indexDB
  async function clearVoix() {
    db = await openDB("medis", 1, {});
    let tx = db.transaction("voix_administrations", "readwrite");
    await tx.objectStore("voix_administrations").clear();
    storeVoix();
  }

  // add users
  const storeUsers = useCallback(async () => {
    var users = await dispatch(fetchUsers());
    var res = await users.payload;
    const tx = db.transaction("users", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("users").add({
        id: res[index].id,
        nom: res[index].nom,
        prenom: res[index].prenom,
        login: res[index].login,
        password: res[index].password,
        email: res[index].email,
        id_role: res[index].id_role,
        etat: res[index].etat,
        token: res[index].token,
        code: res[index].code,
        specialites: res[index].specialites,
        gouvernorats: res[index].gouvernorats,
        roles: res[index].roles,
        clear: 1,
      });
    }
  }, [dispatch]);
  //clear data users from indexDB
  async function clearUser() {
    db = await openDB("medis", 1, {});
    let txGouv = db.transaction("users", "readwrite");
    await txGouv.objectStore("users").clear();
    storeUsers();
  }

  async function init(
    idUser,
    login,
    password,
    nom,
    prenom,
    id_role,
    token,
    code,
    etat
  ) {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("detailUser", "readwrite");
    await tx.objectStore("detailUser").clear();
    await tx.objectStore("detailUser").add({
      user: {
        id: idUser,
        email: login,
        password: password,
        nom: nom,
        prenom: prenom,
        id_role: id_role,
        etat: etat,
        token: token,
        code: code,
        idUser: idUser,
      },
    });
    window.location.replace("/profile");
  }

  //storeDetailUser
  async function initUser(email) {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("users", "readwrite");
    let store = tx.objectStore("users");
    let u = await store.getAll();
    u.forEach((element) => {
      if (email == element.email && element.token != null) {
        localStorage.setItem("x-access-token", element.token);
        navigate.push("/profile");
      } else {
        window.location.reload(true);
      }
    });
  }
  //storeDetailUser
  async function storeDetailUser(email) {
    // console.log(email);
    // db = await openDB("medis", 1, {});
    // let tx = db.transaction("detailUser", "readwrite");
    // const index = tx.store.index("email");
    // console.log(index.iterate(email),'index')
    // for await (let cursor of index.iterate(email)) {
    //   console.log(cursor,'cursor')
    //   var objDetail = { ...cursor.value };
    //   localStorage.setItem("x-access-token", objDetail.token);
    // }
    // console.log(tx.store);
    // await tx.done;
    //window.location.replace("/profile");
    initUser(email);
  }
  //submitForm
  const submitForm = (event) => {
    if (stateLine === false) {
      const promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          var root = await dispatch(
            loginFetch({ login: login, password: password })
          );
          var entities = root.payload;
          resolve(entities);
        }, 0);
      });

      promise.then((value) => {
        if (value.data.length !== 0) {
          var idUser = value.data.data.id;
          var nom = value.data.data.nom;
          var prenom = value.data.data.prenom;
          var id_role = value.data.data.id_role;
          var token = value.data.data.token;
          var code = value.data.data.code;
          var etat = value.data.data.etat;
        }
        if (value.status == 401) {
          notifyErr("Vérfier vos données");
        } else {
          localStorage.setItem("x-access-token", value.data.token);
          init(
            idUser,
            login,
            password,
            nom,
            prenom,
            id_role,
            token,
            code,
            etat
          );
        }
      });
    } else {
      storeDetailUser(login);
    }
  };

  const handleInstallClick = () => {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setDeferredPrompt(null);
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />

      <div className="full-gray section-image" data-color="black">
        <div className="content d-grid align-items-center p-0">
          <Container className="container-login">
            <Col className="mx-auto" lg="5" md="8">
              <Form action="" className="form" method="">
                <Card className={"card-login " + cardClasses + ar}>
                  <div className="flag">
                    <Button
                      className="btn-wd install-app-btn-container"
                      type="button"
                      variant="info"
                      id="installApp"
                      style={{
                        minWidth: "40px",
                        width: "40px",
                        float: "left",
                        marginTop: "5px",
                        marginLeft: "10px",
                        display: deferredPrompt ? "inline" : "none",
                        fontSize: "23px",
                        padding: "3px 10px",
                      }}
                      onClick={handleInstallClick}
                    >
                      <i className="fas fa-mobile-alt"></i>
                    </Button>
                    <img
                      src={require("../../../assets/img/en.png")}
                      alt="en"
                      onClick={(e) => {
                        window.localStorage.setItem("lang", "en");
                        window.location.reload();
                      }}
                    />
                    <img
                      src={require("../../../assets/img/fr.png")}
                      alt="fr"
                      onClick={(e) => {
                        window.localStorage.setItem("lang", "fr");
                        window.location.reload();
                      }}
                    />
                    <img
                      src={require("../../../assets/img/ar.png")}
                      alt="ar"
                      onClick={(e) => {
                        window.localStorage.setItem("lang", "ar");
                        window.location.reload();
                      }}
                    />
                  </div>
                  <img
                    src={require("../../../assets/img/logo.png")}
                    alt="medicacom"
                  />
                  <Card.Body>
                    <Tab.Container id="login-tabs" defaultActiveKey="sign_in">
                      <Nav role="tablist" variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="sign_in">
                            <span>{t("sign_in")}</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="register">
                            {stateLine == false ? (
                              <span>{t("register")}</span>
                            ) : (
                              ""
                            )}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="register">
                          <Inscription></Inscription>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sign_in">
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <Form.Control
                                  onKeyPress={enterKeyPressed}
                                  placeholder={t("User.email")}
                                  type="text"
                                  onChange={loginChange}
                                ></Form.Control>
                              </Form.Group>
                              <br></br>
                            </Col>
                            <Col md="12">
                              <Form.Group className="password-input-group">
                                <Form.Control
                                  placeholder="Password"
                                  type={passwordVisible ? "text" : "password"}
                                  value={password}
                                  onChange={handlePasswordChange}
                                />
                                <span
                                  className={`password-toggle-icon ${
                                    passwordVisible ? "visible" : ""
                                  }`}
                                  onClick={togglePasswordVisibility}
                                >
                                  <i
                                    className={`far ${
                                      passwordVisible
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                  />
                                </span>
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="ml-auto mr-auto footer-login">
                            <Button
                              className="btn-wd"
                              type="button"
                              variant="info"
                              onClick={submitForm}
                            >
                              {t("login")}
                            </Button>
                            <Button
                              /* className="btn-wd" */
                              type="button"
                              variant="link"
                              onClick={() =>
                                window.location.replace("/declaration")
                              }
                            >
                              {t("statement")}
                            </Button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <footer className="footer position-absolute fixed-bottom">
          <Container>
            <nav>
              <ul className="footer-menu d-flex justify-content-center">
                <li>
                  <a
                    className="m-0"
                    href="https://www.medis.com.tn/fr"
                    onClick={(e) => e.preventDefault()}
                  >
                    Medis
                  </a>
                </li>
                <li>
                  <a
                    className="m-0"
                    href="https://www.medis.com.tn/fr/produits"
                    onClick={(e) => e.preventDefault()}
                  >
                    {t("produit")}
                  </a>
                </li>
                <li>
                  <a
                    className="m-0"
                    href="https://www.medis.com.tn/fr/contact"
                    onClick={(e) => e.preventDefault()}
                  >
                    {t("contact")}
                  </a>
                </li>
              </ul>
              <p className="copyright text-center m-0">
                ©
                <a href="https://www.medicacom.tn/fr/">
                  {new Date().getFullYear()} medicacom
                </a>
              </p>
            </nav>
          </Container>
        </footer>
        {/* <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("../../../assets/img/back.jpg") + ")",
          }}
        ></div> */}
      </div>
    </>
  );
}

export default LoginPage;
