import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import {
  marcheImsAdded,
  marcheImsGetById,
} from "../../../Redux/marcheImsReduce";
import { useTranslation } from "react-multi-lang";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

function AjouterMarcheIms() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  if (isNaN(location.id) === true) document.title = "Ajouter un marché";
  else document.title = "Modifier le marché";
  const [lib, setLib] = React.useState("");
  const [id, setId] = React.useState(0);
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  function submitForm(event) {
    if (lib === "") notify("tr", "DCI est obligatoire", "danger");
    else {
      dispatch(marcheImsAdded({ lib, id })).then((e) => {
        if (e.payload === true) {
          if (isNaN(location.id) === true) notify(1, t("add_txt"));
          else notify(1, t("update_txt"));
          setTimeout(async () => {
            listeMarcheIms();
          }, 1500);
        } else {
          notify("tr", "Problème de connexion", "danger");
        }
      });
    }
  }

  useEffect(() => {
    async function getMarcheIms() {
      if (isNaN(location.id) === false) {
        var marcheIms = await dispatch(marcheImsGetById(location.id));
        var entities = marcheIms.payload;
        if (entities === false) {
          navigate("/listMarcheIms");
        } else {
          setLib(entities.lib);
          setId(location.id);
        }
      }
    }
    getMarcheIms();
  }, [location.id, dispatch, navigate]);

  function listeMarcheIms() {
    navigate.push("/listMarcheIms");
    /* window.location.replace("/listMarcheIms"); */
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="success"
                  onClick={listeMarcheIms}
                >
                  <span className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  {t("list")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">
                        {typeof location.id == "undefined"
                            ? t("dci.add")
                            : t("dci.update")}
                        </Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>DCI* </label>
                            <Form.Control
                              defaultValue={lib}
                              placeholder="DCI"
                              type="text"
                              onChange={(value) => {
                                setLib(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Button
                        className="btn-fill pull-right"
                        type="button"
                        variant="success"
                        onClick={submitForm}
                      >
                        Enregistrer
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterMarcheIms;
