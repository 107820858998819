import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchForme = createAsyncThunk("forme/fetchForme", async () => {
    const response = await fetch(Configuration.BACK_BASEURL + "forme/allForme", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
  
    });
    const forme = await response.json();
    return forme;
  });

  export const formeAdded = createAsyncThunk("forme/addForme", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "forme/addForme", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action)
    });
    const forme = await response.json();
    return forme;
  });

  export const formeGetById = createAsyncThunk("forme/formeGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "forme/getForme", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const forme = await response.json();
    return forme;
  });

  export const formeChangeEtat = createAsyncThunk(
    "forme/changeEtat",
    async (id) => {
      var forme = await fetch(
        Configuration.BACK_BASEURL + "forme/changeEtat/" + id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return { status: 403, error: error };
        });
      return forme;
    }
  );
  