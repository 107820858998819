import React, { useCallback } from "react";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { profilUpdated } from "../Redux/usersReduce";
import { fetchRole } from "../Redux/roleReduce";
import { getActiveSpecialite } from "../Redux/specialiteReduce";
import { fetchRootBase } from "../Redux/rootBaseReduce";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { openDB } from "idb";
/* import { Notifications } from "react-push-notification"; */
import { fetchAnnee } from "../Redux/anneeReduce";
import { fetchGouvernorat } from "../Redux/gouvernoratReduce";
import { useTranslation } from "react-multi-lang";
import { fetchAge } from "../Redux/ageReduce";
import { fetchVoix_administration } from "../Redux/voix_administrationReduce";
import { fetchIndication } from "../Redux/indicationReduce";
import { fetchMedicament } from "../Redux/medicamentReduce";
import { getPaysByMed } from "../Redux/paysReduce";
import { fetchPays } from "../Redux/paysReduce";


function Profile({ obj, onlineStatus }) {
  const t = useTranslation();
  let db;
  
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();

  // id user
  var id =  onlineStatus === false ? obj.user.id : obj.id;
  // id role
  var id_role =  onlineStatus === false ? obj.user.id_role : obj.id_role;
  var nomStore =  onlineStatus === false ? obj.user.nom : obj.nom;
  var prenomStore =  onlineStatus === false ? obj.user.prenom : obj.prenom;
  var telStore =  onlineStatus === false ? obj.user.tel : obj.tel;
  var emailStore =  onlineStatus === false ? obj.user.email : obj.email;

  const [nom, setNom] = React.useState(nomStore);
  const [prenom, setPrenom] = React.useState(prenomStore);
  const [tel, setTel] = React.useState(telStore);
  const [email, setEmail] = React.useState(emailStore);
  const [password, setPassword] = React.useState("");
  function submitForm() {
    if (
      nom === "" ||
      prenom === "" ||
      email === "" ||
      (password !== "" && password.length < 6)
    ) {
      notify(2, t("erreur"));
    } else {
      notify(1, t("update_txt"));
      dispatch(profilUpdated({ nom, prenom, tel, email, password, id }));
    }
  }

  //storeRoles
  //save data roles in indexDB
  // const storeRoles = useCallback(async () => {
  //   var role = await dispatch(fetchRole());
  //   var resRole = await role.payload;
  //   const tx = db.transaction("roles", "readwrite");
  //   for (let index = 0; index < resRole.length; index++) {
  //     await tx.objectStore("roles").add({
  //       nom: resRole[index].nom,
  //       order: resRole[index].order,
  //       role: resRole[index].role,
  //       id: resRole[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 2,
  //     });
  //   }
  // }, [dispatch]);

  //clear data roles from indexDB
  // async function clearRole() {
  //   let txRole = db.transaction("roles", "readwrite");
  //   await txRole.objectStore("roles").clear();
  //   storeRoles();
  // }

  //storeAnnee
  //save data annees in indexDB
  // const storeAnnee = useCallback(async () => {
  //   var annees = await dispatch(fetchAnnee());
  //   var resAnnee = await annees.payload;
  //   const tx = db.transaction("annees", "readwrite");
  //   for (let index = 0; index < resAnnee.length; index++) {
  //     await tx.objectStore("annees").add({
  //       annee: resAnnee[index].annee,
  //       id: resAnnee[index].id,
  //     });
  //   }
  // }, [dispatch]);

  // //clear data annees from indexDB
  // async function clearAnnee() {
  //   let txRole = db.transaction("annees", "readwrite");
  //   await txRole.objectStore("annees").clear();
  //   storeAnnee();
  // }

  //storeGouvernorat
  //save data gouvernorats in indexDB
  // const storeGouvernorat = useCallback(async () => {
  //   var gouvernorats = await dispatch(fetchGouvernorat());
  //   var resGouv = await gouvernorats.payload;
  //   const tx = db.transaction("gouvernorats", "readwrite");
  //   for (let index = 0; index < resGouv.length; index++) {
  //     await tx.objectStore("gouvernorats").add({
  //       libelle: resGouv[index].libelle,
  //       id: resGouv[index].id,
  //     });
  //   }
  // }, [dispatch]);

  // //clear data gouvernorats from indexDB
  // async function clearGouvernorats() {
  //   let txGouv = db.transaction("gouvernorats", "readwrite");
  //   await txGouv.objectStore("gouvernorats").clear();
  //   storeGouvernorat();
  // }

  //storeSpecialite
  //save data specialites in indexDB
  // const storeSpecialite = useCallback(async () => {
  //   var gouvernorats = await dispatch(getActiveSpecialite());
  //   var resGouv = await gouvernorats.payload;
  //   const tx = db.transaction("specialites", "readwrite");
  //   for (let index = 0; index < resGouv.length; index++) {
  //     await tx.objectStore("specialites").add({
  //       nom: resGouv[index].nom,
  //       id: resGouv[index].id,
  //     });
  //   }
  // }, [dispatch]);

  // //clear data specialites from indexDB
  // async function clearSpecialite() {
  //   let txGouv = db.transaction("specialites", "readwrite");
  //   await txGouv.objectStore("specialites").clear();
  //   storeSpecialite();
  // }

  //storeRoots
  //save data rootBase in indexDB
  // const storeRoots = useCallback(async () => {
  //   var root = await dispatch(fetchRootBase());
  //   var resRoots = await root.payload;
  //   const tx = db.transaction("rootBase", "readwrite");
  //   for (let index = 0; index < resRoots.length; index++) {
  //     await tx.objectStore("rootBase").add({
  //       id: resRoots[index].id,
  //       name: resRoots[index].name,
  //       name_en: resRoots[index].name_en,
  //       name_ar: resRoots[index].name_ar,
  //       className: resRoots[index].className,
  //       path: resRoots[index].path,
  //       component: resRoots[index].component,
  //       icon: resRoots[index].icon,
  //       role: resRoots[index].role,
  //       ordre: resRoots[index].ordre,
  //       parent: resRoots[index].parent,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 1,
  //     });
  //   }
  // }, [dispatch]);

  // //clear data rootBase from indexDB
  // async function clearRoots() {
  //   let tx = db.transaction("rootBase", "readwrite");
  //   await tx.objectStore("rootBase").clear();
  //   storeRoots();
  // }



  //save data detailUser in indexDB
  // async function storeDetailUser() {
  //   let tx = db.transaction("detailUser", "readwrite");
  //   const index = tx.store.index("email");

  //   let clear = window.localStorage.getItem("clear");
  //   if (clear) {
  //     if (parseInt(clear) === 2) {
  //       for await (let cursor of index.iterate(obj.user.email)) {
  //         var objDetail = { ...cursor.value };
  //         objDetail.id = obj.user.id;
  //         objDetail.nom = obj.user.nom;
  //         objDetail.prenom = obj.user.prenom;
  //         objDetail.login = obj.user.email;
  //         objDetail.password = obj.user.password;
  //         objDetail.email = obj.user.email;
  //         objDetail.id_role = obj.user.id_role;
  //         objDetail.etat = obj.user.etat;
  //         objDetail.token = obj.user.token;
  //         objDetail.code = obj.user.code;
  //         objDetail.specialites = obj.user.specialites;
  //         objDetail.gouvernorats = obj.user.gouvernorats;
  //         objDetail.roles = obj.user.roles;
  //         objDetail.clear = 2;
  //         cursor.update(objDetail);
  //       }
  //       await tx.done;
  //     } else {
  //       await tx.objectStore("detailUser").add({
  //         user:obj.user,
  //         clear: 1,
  //       });
  //       window.localStorage.setItem("clear", "2");
  //     }
  //   }
  // }

  //storeAge
  //save data ages in indexDB
  // const storeAge = useCallback(async () => {
  //   var age = await dispatch(fetchAge());
  //   var res = await age.payload;
  //   const tx = db.transaction("ages", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("ages").add({
  //       description: res[index].description,
  //       description_en: res[index].description_en,
  //       description_ar: res[index].description_ar,
  //       etat: res[index].etat,
  //       id: res[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);

  // //clear data ages from indexDB
  // async function clearAge() {
  //   let txMedicament = db.transaction("ages", "readwrite");
  //   await txMedicament.objectStore("ages").clear();
  //   storeAge();
  // }

  //storeVoix
  //save data voix_administrations in indexDB
  // const storeVoix = useCallback(async () => {
  //   var age = await dispatch(fetchVoix_administration());
  //   var res = await age.payload;
  //   const tx = db.transaction("voix_administrations", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("voix_administrations").add({
  //       description: res[index].description,
  //       description_en: res[index].description_en,
  //       description_ar: res[index].description_ar,
  //       etat: res[index].etat,
  //       id: res[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);

  // //clear data voix_administrations from indexDB
  // async function clearVoix() {
  //   let tx = db.transaction("voix_administrations", "readwrite");
  //   await tx.objectStore("voix_administrations").clear();
  //   storeVoix();
  // }

  // //storeAge
  // //save data indications in indexDB
  // const storeIndication = useCallback(async () => {
  //   var age = await dispatch(fetchIndication());
  //   var res = await age.payload;
  //   const tx = db.transaction("indications", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("indications").add({
  //       description: res[index].description,
  //       description_en: res[index].description_en,
  //       description_ar: res[index].description_ar,
  //       etat: res[index].etat,
  //       id: res[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);

  // //clear data indications from indexDB
  // async function clearIndications() {
  //   let tx = db.transaction("indications", "readwrite");
  //   await tx.objectStore("indications").clear();
  //   storeIndication();
  // }

  // //storeMedicament
  // //save data medicaments in indexDB
  // const storeMedicament = useCallback(async () => {
  //   var age = await dispatch(fetchMedicament());
  //   var res = await age.payload;
  //   const tx = db.transaction("medicaments", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("medicaments").add({
  //       nom: res[index].nom,
  //       nom_en: res[index].nom_en,
  //       nom_ar: res[index].nom_ar,
  //       etat: res[index].etat,
  //       dosage: res[index].dosage,
  //       presentation: res[index].presentation,
  //       id: res[index].id,
  //       id_pays: res[index].id_pays,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);
  // //clear data medicaments from indexDB
  // async function clearMedicament() {
  //   let tx = db.transaction("medicaments", "readwrite");
  //   await tx.objectStore("medicaments").clear();
  //   storeMedicament();
  // }

  // //save data pays in indexDB
  // const storePays = useCallback(async () => {
  //   var age = await dispatch(fetchPays());
  //   var res = await age.payload;
  //   const tx = db.transaction("pays", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("pays").add({
  //       code: res[index].code,
  //       nom: res[index].nom,
  //       nom_en: res[index].nom_en,
  //       alpha2: res[index].alpha2,
  //       alpha3: res[index].alpha3,
  //       etat: res[index].etat,
  //       id: res[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);
  // //clear data pays from indexDB
  // async function clearPays() {
  //   let tx = db.transaction("pays", "readwrite");
  //   await tx.objectStore("pays").clear();
  //   storePays();
  // }


  //  //save data paysMed in indexDB
  //  const storePaysMed = useCallback(async () => {
  //   var age = await dispatch(getPaysByMed());
  //   var res = await age.payload;
  //   const tx = db.transaction("paysMed", "readwrite");
  //   for (let index = 0; index < res.length; index++) {
  //     await tx.objectStore("paysMed").add({
  //       code: res[index].code,
  //       nom: res[index].nom,
  //       nom_en: res[index].nom_en,
  //       alpha2: res[index].alpha2,
  //       alpha3: res[index].alpha3,
  //       etat: res[index].etat,
  //       id: res[index].id,
  //       saved: 1,
  //       updated: 0,
  //       deleted: 0,
  //       type_table: 9,
  //     });
  //   }
  // }, []);
  // //clear data paysMed from indexDB
  // async function clearPaysMed() {
  //   let tx = db.transaction("paysMed", "readwrite");
  //   await tx.objectStore("paysMed").clear();
  //   storePaysMed();
  // }

 

  //save data in indexDB medis
  async function init() {
    //db = await openDB("medis", 1, {});
    if ( onlineStatus === false) {
      // clearRole();
      // clearRoots();
      // clearAnnee();
      // clearGouvernorats();
      // clearSpecialite();
      // clearAge();
      // clearVoix();
      // clearIndications();
      // clearMedicament();
      // clearPays();
      // storeDetailUser();
      // clearPaysMed();
    }
  }

  React.useEffect(() => {
    if ( onlineStatus === false) {
      init();
    }
  }, [init]);
  return (
    <>
      <ToastContainer />
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">{t("profile")}</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>{t("User.name")}* </label>
                            <Form.Control
                              defaultValue={nom}
                              placeholder={t("User.name")}
                              type="text"
                              onChange={(value) => {
                                setNom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>{t("User.last_name")} </label>
                            <Form.Control
                              defaultValue={prenom}
                              placeholder={t("User.last_name")}
                              type="text"
                              onChange={(value) => {
                                setPrenom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>{t("User.email")}*</label>
                            <Form.Control
                              id="Email_user"
                              defaultValue={email}
                              placeholder={t("User.email")}
                              type="text"
                              onChange={(value) => {
                                setEmail(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>
                              {t("User.password")}* ({t("User.password_err")})
                            </label>
                            <Form.Control
                              id="mdp_user"
                              defaultValue=""
                              placeholder="Mote de passe"
                              type="password"
                              onChange={(value) => {
                                setPassword(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>{t("User.tel")} </label>
                            <Form.Control
                              defaultValue={tel}
                              placeholder="Telephone"
                              type="text"
                              onChange={(value) => {
                                setTel(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Notifications /> */}
                      <Button
                        className="btn-fill pull-right"
                        type="button"
                        variant="success"
                        onClick={submitForm}
                      >
                        {t("save")}
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default Profile;
