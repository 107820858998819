import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchCategorieAge = createAsyncThunk("categorieAge/fetchCategorieAge", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "categorieAge/allCategorieAge", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const categorieAge = await response.json();
  return categorieAge;
});

export const categorieAgeGetById = createAsyncThunk("categorieAge/categorieAgeGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "categorieAge/getCategorieAge", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const categorieAge = await response.json();
  return categorieAge;
});

export const categorieAgeChangeEtat = createAsyncThunk("categorieAge/changeEtat",
  async (id) => {
    var categorieAge = await fetch(
      Configuration.BACK_BASEURL + "age/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return { status: 403, error: error };
      });
    return categorieAge;
  }
);

export const categorieAgeAdded = createAsyncThunk("categorieAge/addCategorieAge", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "categorieAge/addCategorieAge", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action)
  });
  const categorieAge = await response.status;
  return categorieAge;
});