import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchAction = createAsyncThunk("action/fetchAction", async () => {
    const response = await fetch(Configuration.BACK_BASEURL + "action/allAction", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
  
    });
    const action = await response.json();
    return action;
  });

  const actionReduce = createSlice({
    name: "action",
    initialState: {
      entities: [],
      loading: false,
    },
    reducers: {
    },
    extraReducers: {
  
      [fetchAction.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAction.fulfilled]: (state, action) => {
        state.loading = false;
        state.entities = [...state.entities, ...action.payload];
      },
      [fetchAction.rejected]: (state, action) => {
        state.loading = false;
      },
    },
  });
  
  export default actionReduce.reducer;