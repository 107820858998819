import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { fetchPays } from "../../../Redux/paysReduce";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-multi-lang";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_AR } from "../../utils/ar_table";
import { openDB } from "idb";

// core components
function ListPays({ onlineStatus }) {
  let db;
  let lang = window.localStorage.getItem("lang");
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: t("name"),
        accessorKey: "nom",
      },
      {
        header: t("actions"),
        accessorKey: "id",
        Cell: ({ cell, row }) =>
           onlineStatus === false ? (
            <div className="actions-right block_action">
              <Button
                onClick={() => {
                  navigate.push("/pays/update/" + cell.row.original.id);
                }}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>
            </div>
          ) : (
            ""
          ),
      },
      //end
    ],
    []
  );

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        localization={
          lang === "fr"
            ? MRT_Localization_FR
            : lang === "ar"
            ? MRT_Localization_AR
            : MRT_Localization_EN
        }
      />
    );
  }

  function ajouter() {
    navigate.push("/ajouterPays");
  }

  //storePays
  const storePays = useCallback(async (res) => {
    const tx = db.transaction("pays", "readwrite");
    for (let index = 0; index < res.length; index++) {
      await tx.objectStore("pays").add({
        nom: res[index].nom,
        nom_en: res[index].nom_en,
        code: res[index].code,
        alpha2: res[index].alpha2,
        alpha3: res[index].alpha3,
        etat: res[index].etat,
        id: res[index].id,
        saved: 1,
        updated: 0,
        deleted: 0,
        type_table: 9,
      });
    }
  }, []);

  async function clearPays(res) {
    let txPays = db.transaction("pays", "readwrite");
    await txPays.objectStore("pays").clear();
    if (res.length != 0) storePays(res);
  }

  const getPays = useCallback(async () => {
    var pays = await dispatch(fetchPays());
    var res = await pays.payload;
    setEntities(res);
    clearPays(res);
  }, [dispatch]);

  async function initPays() {
    const tx = db.transaction("pays", "readwrite");
    let paysStore = tx.objectStore("pays");
    let payss = await paysStore.getAll();
    setEntities(payss);
  }

  async function init() {
    db = await openDB("medis", 1, {});
    if ( onlineStatus === false) getPays();
    else {
      initPays();
    }
  }

  useEffect(() => {
    /* getPays() */
    init();
  }, [getPays]); //now shut up eslint
  return (
    <>
      <Container fluid>
        <Row>
          { onlineStatus === false ? (
            <Col md="12">
              <Button
                id="saveBL"
                className="btn-wd  mr-1 float-left"
                type="button"
                variant="success"
                onClick={ajouter}
              >
                <span className="btn-label">
                  <i className="fas fa-plus"></i>
                </span>
                {t("pays.add")}
              </Button>
            </Col>
          ) : (
            ""
          )}
          <Col md="12">
            <h4 className="title">{t("pays.list")}</h4>
            <Card>
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListPays;
