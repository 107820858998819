import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import {
  getDeclarations,
  getDeclarationsById,
} from "../../Redux/declarationReduce";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-multi-lang";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_AR } from "../utils/ar_table";
import { openDB } from "idb";
import ExcelJs from "exceljs";
import useOfflineStatus from "../../Offline";

// core components
function ListDeclaration({ obj, onlineStatus }) {
  let db;
  var stateLine = useOfflineStatus();
  var id_role = stateLine === false ? obj.user.id_role : obj.id_role;
  var id = stateLine === false ? obj.user.id : obj.idUser;

  if (stateLine === true) {
    var idUserOff = obj.idUser;
  }

  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;

  let lang = window.localStorage.getItem("lang");
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [alert, setAlert] = React.useState(null);
  const [entities, setEntities] = React.useState([]);
  const [entitiesExcel, setEntitiesExcel] = React.useState([]);

  const columns = useMemo(
    () => [
      {
        header: t("Declaration.data"),
        accessorKey: "nomNotificateur",
      },

      {
        header: t("Declaration.drugs"),
        accessorKey: "medicaments",
      },
      {
        header: t("Declaration.patient"),
        accessorKey: "patient",
      },
      {
        header: t("Declaration.category"),
        accessorKey: "categorieAge",
      },
      {
        header: t("Declaration.detail"),
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                if (stateLine === false) getDetails(cell.row.original.id);
                else {
                  initDetail(cell.row.original.id);
                }
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );

  function ajouter() {
    navigate.push("/declaration");
  }

  //storeDeclaration
  //save declaration in indexDB
  const storeDeclaration = useCallback(
    async (res) => {
      const tx = db.transaction("declarations", "readwrite");
      for (let index = 0; index < res.length; index++) {
        var nomAge = "";
        if (res[index].patients.ages) {
          nomAge =
            res[index].patients.ages.description +
            "@@" +
            res[index].patients.ages.description_en +
            "@@" +
            res[index].patients.ages.description_ar;
        }
        var nomMed =
          res[index].medicaments.nom +
          "@@" +
          res[index].medicaments.nom_en +
          "@@" +
          res[index].medicaments.nom_ar;

        var nomInd = "";
        if (res[index].patients.indications) {
          nomInd =
            res[index].patients.indications.id !== 0
              ? res[index].patients.indications.description +
                "@@" +
                res[index].patients.indications.description_en +
                "@@" +
                res[index].patients.indications.description_ar
              : "";
        }

        if (res[index].voix_administrations) {
          var nomVoix =
            res[index].voix_administrations.description +
            "@@" +
            res[index].voix_administrations.description_en +
            "@@" +
            res[index].voix_administrations.description_ar;
        }
        /* var nomEff =
          res[index].effet_indesirables.description +
          "@@" +
          res[index].effet_indesirables.description_en +
          "@@" +
          res[index].effet_indesirables.description_ar; */
        var nomEff = res[index].effet;

        await tx.objectStore("declarations").add({
          patients: res[index].patients,
          id_patient: res[index].patients.id,
          initiales: res[index].patients.initiales,
          age: res[index].patients.age,
          sexe: res[index].patients.sexe,
          dateNaissance: res[index].patients.dateNaissance,
          agePatient: res[index].patients.agePatient,
          ageCategorie: res[index].patients.ageCategorie,
          id_indication: res[index].patients.id_indication,
          id_passager: res[index].patients.id_passager,
          poid: res[index].patients.poid,
          taille: res[index].patients.taille,
          allergie: res[index].patients.allergie,
          dateDebut: res[index].dateDebut,
          dateFin: res[index].dateFin,
          information: res[index].information,
          complementaires: res[index].complementaires,
          posologie: res[index].posologie,
          numero: res[index].numero,
          dateDebutAdmin: res[index].dateDebutAdmin,
          dateFinAdmin: res[index].dateFinAdmin,
          id_medicament: res[index].id_medicament,
          id: res[index].id,
          id_user: res[index].id_user,
          users: res[index].users,
          nom_user: res[index].nom + " " + res[index].prenom,
          id_eff: res[index].id_eff,
          id_voix: res[index].id_voix,
          id_patient: res[index].id_patient,
          id_passager: res[index].id_passager,
          passagers: res[index].passagers,
          description_eff: res[index].description_eff,
          grave: res[index].grave,
          hospitalisation: res[index].hospitalisation,
          pronostic: res[index].pronostic,
          incapacite: res[index].incapacite,
          deces: res[index].deces,
          anomalie: res[index].anomalie,
          autre: res[index].autre,
          traites: res[index].traites,
          evolution: res[index].evolution,
          survenus: res[index].survenus,
          date_admin: res[index].date_admin,
          therapeutique: res[index].therapeutique,
          nomMed: nomMed,
          nomInd: nomInd,
          nomVoix: nomVoix,
          nomEff: nomEff,
          nomAge: nomAge,
          createdAt: res[index].patients.createdAt,
          createdAt: res[index].patients.updatedAt,
          id_passager: res[index].patients.id_passager,
          medicaments: res[index].medicaments,
          effet_indesirables: res[index].effet_indesirables,
          voix_administrations: res[index].voix_administrations,
        });
        /* storeRapports(res[index]); */
      }
    },
    [dispatch]
  );

  //clear declaration from indexDB
  async function clearDeclaration(resUsers) {
    let tx1 = db.transaction("declarations", "readwrite");
    await tx1.objectStore("declarations").clear();
    storeDeclaration(resUsers);
  }

  //get declaration from mysql
  const getDeclaration = useCallback(async () => {
    var response = await dispatch(getDeclarations({ id_role, id, annee }));
    var dec = response.payload;
    var array = [];
    dec.forEach((element) => {
      var nomNotificateur = element.users
        ? element.users.nom + " " + element.users.prenom
        : element.passagers.nom + " " + element.passagers.prenom;

      var sp = "";
      if (lang === "fr") {
        sp = element.users
          ? element.users.specialites.nom
          : element.passagers.specialites.nom;
      } else if (lang === "en") {
        sp = element.users
          ? element.users.specialites.nom_en
          : element.passagers.specialites.nom_en;
      } else {
        sp = element.users
          ? element.users.specialites.nom_ar
          : element.passagers.specialites.nom_ar;
      }

      var patient =
        lang === "fr"
          ? element.patients.initiales
          : lang === "en"
          ? element.patients.initiales
          : element.patients.initiales;

      var med =
        lang === "fr"
          ? element.medicaments.nom
          : lang === "en"
          ? element.medicaments.nom_en
          : element.medicaments.nom_ar;

      var categorieAge = "";
      if (lang === "fr") {
        if (
          element.patients.ages !== null &&
          element.patients.ageCategorie !== null
        ) {
          categorieAge = element.patients.ages.description;
        }
      }

      if (lang === "en") {
        if (
          element.patients.ages !== null &&
          element.patients.ageCategorie !== null
        ) {
          categorieAge = element.patients.ages.description_en;
        }
      }

      if (lang === "ar") {
        if (
          element.patients.ages !== null &&
          element.patients.ageCategorie !== null
        ) {
          categorieAge = element.patients.ages.description_ar;
        }
      }

      var id = element.id;
      array.push({
        id: id,
        //nomNot: nom,
        medicaments: med,
        specialite: sp,
        categorieAge: categorieAge,
        patient: patient,
        nomNotificateur: nomNotificateur,
      });
    });
    setEntities(array);
    setEntitiesExcel(dec);
    clearDeclaration(dec);
  }, [dispatch]);

  //get detail declaration by id from mysql
  const getDetails = useCallback(
    async (idD) => {
      var dec = await dispatch(getDeclarationsById(idD));
      var data = await dec.payload;
      confirmMessage(data);
    },
    [dispatch]
  );

  //get detail declaration by id from indexDb
  async function initDetail(idD) {
    db = await openDB("medis", 1, {});
    const tx = db.transaction("declarations", "readwrite");
    const index = tx.store.index("id");
    var array = [];
    for await (const cursor of index.iterate(parseInt(idD))) {
      var obj = { ...cursor.value };
      array.push(obj);
    }
    await tx.done;
    confirmMessage(array[0]);
  }

  //pop-up detail declaration
  const confirmMessage = async (data) => {
    var nomAge = "";
    var nomInd = "";
    var nomCategorieAge = "";
    if (data.patients.ages) {
      nomAge =
        lang === "fr"
          ? data.patients.ages.description
          : lang === "en"
          ? data.patients.ages.description_en
          : data.patients.ages.description_ar;
    }
    if (data.patients.categorie_ages) {
      nomCategorieAge =
        lang === "fr"
          ? data.patients.categorie_ages.description
          : lang === "en"
          ? data.patients.categorie_ages.description_en
          : data.patients.categorie_ages.description_ar;
    }
    var nomMed =
      lang === "fr"
        ? data.medicaments.nom
        : lang === "en"
        ? data.medicaments.nom_en
        : data.medicaments.nom_ar;
    if (data.patients.indications && data.patients.indications.id !== 0) {
      nomInd =
        lang === "fr"
          ? data.patients.indications.description
          : lang === "en"
          ? data.patients.indications.description_en
          : data.patients.indications.description_ar;
    }
    if (data.voix_administrations) {
      var nomVoix =
        lang === "fr"
          ? data.voix_administrations.description
          : lang === "en"
          ? data.voix_administrations.description_en
          : data.voix_administrations.description_ar;
    }
    if (data.formes) {
      var nomForme =
        lang === "fr"
          ? data.formes.nom
          : lang === "en"
          ? data.formes.nom_en
          : data.formes.nom_ar;
    }

    if (data.actions) {
      var nomAction =
        lang === "fr"
          ? data.actions.nom_fr
          : lang === "en"
          ? data.actions.nom_en
          : data.actions.nom_ar;
    }

    var nomEff = data.description_eff;
    /*  var nomEff =
      lang === "fr"
        ? data.voix_administrations.description
        : lang === "en"
        ? data.effet_indesirables.description_en
        : data.effet_indesirables.description_ar;
    */
    setAlert(
      <SweetAlert
        customClass="pop-up-extra"
        style={{ display: "block", marginTop: "100px" }}
        title={t("Declaration.details_dec")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("Declaration.fermer")}
      >
        <Row className="row-margin">
          <Col md="4">
            <h3>{t("Declaration.patient")}</h3>
            <ul>
              <li>
                <strong>{t("Declaration.personal")}: </strong>
                {data.users
                  ? data.users.nom + " " + data.users.prenom
                  : data.passagers.nom + " " + data.passagers.prenom}
              </li>
              <li>
                <strong>{t("Declaration.dateInsert")}: </strong>
                {new Date(
                  new Date(data.patients.createdAt).getTime() -
                    new Date(data.patients.createdAt).getTimezoneOffset() *
                      60000
                )
                  .toISOString()
                  .slice(0, 10)}
              </li>
              <li>
                <strong>{t("Declaration.initials")}: </strong>
                {data.patients.initiales}
              </li>
              <li>
                <strong>{t("Declaration.gendre")}: </strong>
                {data.patients.sexe === 1
                  ? t("Declaration.man")
                  : data.patients.sexe === 2
                  ? t("Declaration.woman")
                  : t("Declaration.other")}
              </li>
              <li>
                <strong>
                  {data.patients.age === 1
                    ? t("Declaration.date")
                    : data.patients.age === 2
                    ? t("Declaration.age")
                    : t("Declaration.age")}
                  :{" "}
                </strong>
                {data.patients.age === 1
                  ? data.patients.dateNaissance
                  : data.patients.age === 2
                  ? data.patients.agePatient + " " + nomCategorieAge
                  : nomAge}
              </li>
              <li>
                <strong>{t("Declaration.indication")}: </strong>
                {nomInd === null || nomInd == "" ? "-" : nomInd}
              </li>
              <li>
                <strong>{t("Declaration.poid")}: </strong>
                {data.patients.poid}
              </li>
              <li>
                <strong>{t("Declaration.taille")}: </strong>
                {data.patients.taille}
              </li>
              <li>
                <strong>{t("Declaration.allergie_pop")}: </strong>
                {data.patients.allergie == null || data.patients.allergie == ""
                  ? "-"
                  : data.patients.allergie}
              </li>
            </ul>
          </Col>
          <Col md="4">
            <h3>{t("Declaration.drugs")}</h3>
            <ul>
              <li>
                <strong>{t("Declaration.name_drug")}: </strong>
                {nomMed}
              </li>
              <li>
                <strong>{t("Declaration.numero")}: </strong>
                {data.numero == null || data.numero == "" ? "-" : data.numero}
              </li>
              <li>
                <strong>{t("Declaration.dosage")}: </strong>
                {data.posologie}
              </li>{" "}
              <li>
                <strong>{t("Declaration.forme")}: </strong>
                {nomForme == null || nomForme == "" ? "-" : nomForme}
              </li>
              <li>
                <strong>{t("Declaration.voice")}: </strong>
                {nomVoix == null || nomVoix == "" ? "-" : nomVoix}
              </li>
              <li>
                <strong>{t("Declaration.start")}: </strong>
                {data.dateDebutAdmin == null || data.dateDebutAdmin == ""
                  ? "-"
                  : data.dateDebutAdmin}
              </li>
              <li>
                <strong>{t("Declaration.end")}: </strong>
                {data.dateFinAdmin == null || data.dateFinAdmin == ""
                  ? "-"
                  : data.dateFinAdmin}
              </li>{" "}
              <li>
                <strong>{t("Declaration.date_admin")}: </strong>
                {data.date_admin == null || data.date_admin == ""
                  ? "-"
                  : data.date_admin}
              </li>
              <li>
                <strong>{t("Drugs.action")}: </strong>
                {nomAction == null || nomAction == "" ? "-" : nomAction}
              </li>
              <li>
                <strong>{t("Declaration.therapeutique")}: </strong>
                {data.therapeutique == null || data.therapeutique == ""
                  ? "-"
                  : data.therapeutique}
              </li>
              <li>
                <strong>{t("Declaration.effects")}: </strong>
                {data.effet == null || data.effet == "" ? "-" : data.effet}
              </li>
            </ul>
          </Col>
          <Col md="4">
            <h3>{t("Declaration.effects")}</h3>
            <ul>
              <li>
                <strong>{t("Declaration.effects")}: </strong>
                {nomEff}
              </li>
              <li>
                <strong>{t("Declaration.file_txt")}: </strong>
                {data.patients.img ? (
                  <a
                    href={"data:image/png;base64," + data.patients.img}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <i className="fas fa-paperclip"></i>
                    {t("Declaration.attachment")}
                  </a>
                ) : (
                  "-"
                )}
              </li>
              <li>
                <strong>{t("Declaration.start")}: </strong>
                {data.dateDebut == null || data.dateDebut == ""
                  ? "-"
                  : data.dateDebut}
              </li>
              <li>
                <strong>{t("Declaration.end")}: </strong>
                {data.dateFin == null || data.dateFin == ""
                  ? "-"
                  : data.dateFin}
              </li>
              <li>
                <strong>{t("Declaration.information")}: </strong>
                {data.information == null || data.information == ""
                  ? "-"
                  : data.information}
              </li>
              <li>
                <strong>{t("Declaration.complementary")}: </strong>
                {data.complementaires == null || data.complementaires == ""
                  ? "-"
                  : data.complementaires}
              </li>
              <hr></hr>
              <li>
                <strong>{t("Declaration.gravite")} </strong>
                <br></br>
                <ul className="ul-effect">
                  <li>
                    {" "}
                    <strong>{t("Declaration.grave") + ": "}</strong>
                    {data.grave === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    {" "}
                    <strong>{t("Declaration.hospitalisation") + ": "}</strong>
                    {data.hospitalisation === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    {" "}
                    <strong>{t("Declaration.pronostic") + ": "}</strong>
                    {data.pronostic === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    <strong>{t("Declaration.deces") + ": "}</strong>
                    {data.deces === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    <strong>{t("Declaration.incapacite") + ": "}</strong>
                    {data.incapacite === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    <strong>{t("Declaration.anomalie") + ": "}</strong>
                    {data.anomalie === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                  <li>
                    <strong>{t("Declaration.autre") + ": "}</strong>
                    {data.autre === 1
                      ? t("Declaration.yes")
                      : t("Declaration.no")}
                  </li>
                </ul>

                {/* <br></br>

                <br></br>
              
                <br></br>
           
                <br></br>
             
                <br></br>
               
                <br></br> */}
              </li>
              <hr></hr>
              <li>
                <strong>{t("Declaration.traites")}: </strong>
                {data.traites === 1
                  ? t("Declaration.traites_yes")
                  : data.traites === 2
                  ? t("Declaration.traites_no")
                  : data.traites === 3
                  ? t("Declaration.traites_inc")
                  : "-"}
              </li>
              <hr></hr>
              <li>
                <strong>{t("Declaration.evolution")}: </strong>
                {data.evolution === 1
                  ? t("Declaration.evolution_txt1")
                  : data.evolution === 2
                  ? t("Declaration.evolution_txt2")
                  : data.evolution === 3
                  ? t("Declaration.evolution_txt3")
                  : data.evolution === 4
                  ? t("Declaration.evolution_txt4")
                  : data.evolution === 5
                  ? t("Declaration.evolution_txt5")
                  : data.evolution === 6
                  ? t("Declaration.evolution_txt6")
                  : "-"}
              </li>
              <hr></hr>
              <li>
                <strong>{t("Declaration.survenus")} </strong>
                <br></br>
                {data.survenus === 1
                  ? t("Declaration.survenus_txt1")
                  : data.survenus === 2
                  ? t("Declaration.survenus_txt2")
                  : data.survenus === 3
                  ? t("Declaration.survenus_txt3")
                  : data.survenus === 4
                  ? t("Declaration.survenus_txt4")
                  : data.survenus === 5
                  ? t("Declaration.survenus_txt5")
                  : data.survenus === 6
                  ? t("Declaration.survenus_txt6")
                  : "-"}
              </li>
            </ul>
          </Col>
        </Row>
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  //get declaration from indexdb
  async function initDeclaration() {
    const tx = db.transaction("declarations", "readwrite");
    let store = tx.objectStore("declarations");
    let dec = await store.getAll();
    var array = [];
    dec.forEach((element) => {
      var datee = element.createdAt.split("T")[0];
      var year = datee.split("-")[0];

      if (year == annee) {
        if (id_role == 2) {
          if (element.id_user == idUserOff) {
            var nomNotificateur = element.users
              ? element.users.nom + " " + element.users.prenom
              : element.passagers.nom + " " + element.passagers.prenom;
            var med =
              lang === "fr"
                ? element.medicaments.nom
                : lang === "en"
                ? element.medicaments.nom_en
                : element.medicaments.nom_ar;

            var patient =
              lang === "fr"
                ? element.patients.initiales
                : lang === "en"
                ? element.patients.initiales
                : element.patients.initiales;

            var categorieAge = "";
            if (lang === "fr") {
              if (
                element.patients.ages !== null &&
                element.patients.ageCategorie !== null
              ) {
                categorieAge = element.patients.ages.description;
              }
            }

            if (lang === "en") {
              if (
                element.patients.ages !== null &&
                element.patients.ageCategorie !== null
              ) {
                categorieAge = element.patients.ages.description_en;
              }
            }

            if (lang === "ar") {
              if (
                element.patients.ages !== null &&
                element.patients.ageCategorie !== null
              ) {
                categorieAge = element.patients.ages.description_ar;
              }
            }

            var date = new Date(
              new Date(element.patients.createdAt).getTime() -
                new Date(element.patients.createdAt).getTimezoneOffset() * 60000
            )
              .toISOString()
              .slice(0, 10);
            var id = element.id;
          }
        } else {
          var nomNotificateur = element.users
            ? element.users.nom + " " + element.users.prenom
            : element.passagers.nom + " " + element.passagers.prenom;
          var med =
            lang === "fr"
              ? element.medicaments.nom
              : lang === "en"
              ? element.medicaments.nom_en
              : element.medicaments.nom_ar;

          var patient =
            lang === "fr"
              ? element.patients.initiales
              : lang === "en"
              ? element.patients.initiales
              : element.patients.initiales;

          var categorieAge = "";
          if (lang === "fr") {
            if (
              element.patients.ages !== null &&
              element.patients.ageCategorie !== null
            ) {
              categorieAge = element.patients.ages.description;
            }
          }

          if (lang === "en") {
            if (
              element.patients.ages !== null &&
              element.patients.ageCategorie !== null
            ) {
              categorieAge = element.patients.ages.description_en;
            }
          }

          if (lang === "ar") {
            if (
              element.patients.ages !== null &&
              element.patients.ageCategorie !== null
            ) {
              categorieAge = element.patients.ages.description_ar;
            }
          }

          var date = new Date(
            new Date(element.patients.createdAt).getTime() -
              new Date(element.patients.createdAt).getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10);
          var id = element.id;
        }

        array.push({
          id: id,
          medicaments: med,
          createdAt: date,
          categorieAge: categorieAge,
          patient: patient,
          nomNotificateur: nomNotificateur,
        });
      }
    });
    console.log(array)
    setEntities(array);
    setEntitiesExcel(dec);
  }

  //open DB medis indexDb
  async function init() {
    db = await openDB("medis", 1, {});
    if (stateLine === false) getDeclaration();
    else {
      initDeclaration();
    }
  }

  useEffect(() => {
    init();
  }, []); //now shut up eslint

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        localization={
          lang === "fr"
            ? MRT_Localization_FR
            : lang === "ar"
            ? MRT_Localization_AR
            : MRT_Localization_EN
        }
      />
    );
  }

  //exportEcel
  const exportToExcel = useCallback(async (data) => {
    /* var first = Object.keys(res)[0] */

    let sheetName = `Déclaration.xlsx`;
    let headerName = "RequestsList";

    // showGridLines: false
    let workbook = new ExcelJs.Workbook();
    let sheet = workbook.addWorksheet(sheetName, {
      views: [{ showGridLines: true }],
    });
    // let sheet2 = workbook.addWorksheet("Second sheet", { views: [{ showGridLines: false }] });

    // header
    let columnArr = [
      { name: t("Declaration.personal") },
      { name: t("Declaration.date") },
      { name: t("Declaration.initials") },
      { name: t("Declaration.gendre") },
      { name: t("Declaration.age") },
      { name: t("Declaration.indication") },
      { name: t("Declaration.poid") },
      { name: t("Declaration.taille") },
      { name: t("Declaration.allergie_pop") },
      { name: t("Declaration.name_drug") },
      { name: t("Declaration.numero") },
      { name: t("Declaration.dosage") },
      { name: t("Declaration.forme") },
      { name: t("Drugs.action") },
      { name: t("Declaration.therapeutique") },
      { name: t("Declaration.voice") },
      { name: t("Declaration.start") },
      { name: t("Declaration.end") },
      { name: t("Declaration.effects") },
      { name: t("Declaration.start") },
      { name: t("Declaration.end") },
      { name: t("Declaration.information") },
      { name: t("Declaration.grave") },
      { name: t("Declaration.complementary") },
      { name: t("Declaration.hospitalisation") },
      { name: t("Declaration.pronostic") },
      { name: t("Declaration.deces") },
      { name: t("Declaration.incapacite") },
      { name: t("Declaration.anomalie") },
      { name: t("Declaration.autre") },
      { name: t("Declaration.traites") },
      { name: t("Declaration.evolution") },
      { name: t("Declaration.survenus") },
    ];
    /* for (let i in res[first][0]) {
      if(i !=="sujet" && i !== "titre"){
        let tempObj = { name: "" };
        tempObj.name = i;
        columnArr.push(tempObj);
      }
    } */
    sheet.addTable({
      name: `Header`,
      ref: "F1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "",
        showRowStripes: false,
        showFirstColumn: true,
        width: 200,
      },
      columns: [{ name: "Déclaration : " }],
      rows: [[``]],
    });
    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "",
        showRowStripes: false,
        showFirstColumn: true,
        width: 200,
      },
      columns: [{ name: "Déclaration" }],
      rows: [[``]],
    });
    sheet.addTable({
      name: headerName,
      ref: "A3",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: true,
        width: 200,
      },
      columns: columnArr ? columnArr : [{ name: "" }],
      rows: data.map((e, k) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("F1").font = { size: 25, bold: true };
    sheet.getCell("A1").font = { size: 25, bold: true };

    /* const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      for (let j = 0; j <= table.table.rows.length; j++) {
        sheet.getCell(`${String.fromCharCode(65 + i)}${j + 3}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    } */

    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
  }, []);

  //get data export excel
  const getDetail = useCallback(
    async (res) => {
      var array = [];
      res.forEach((data) => {
        var nomAge = "";

        //ages patients
        if (data.patients.ages) {
          nomAge =
            lang === "fr"
              ? data.patients.ages.description
              : lang === "en"
              ? data.patients.ages.description_en
              : data.patients.ages.description_ar;
        }

        //medicaments
        var nomMed =
          lang === "fr"
            ? data.medicaments.nom
            : lang === "en"
            ? data.medicaments.nom_en
            : data.medicaments.nom_ar;

        //indications
        if (data.patients.indications) {
          var nomInd =
            lang === "fr"
              ? data.patients.indications.description
              : lang === "en"
              ? data.patients.indications.description_en
              : data.patients.indications.description_ar;
        }

        //voix_administrations
        if (data.voix_administrations) {
          var nomVoix =
            lang === "fr"
              ? data.voix_administrations.description
              : lang === "en"
              ? data.voix_administrations.description_en
              : data.voix_administrations.description_ar;
        }

        if (data.formes) {
          var nomForme =
            lang === "fr"
              ? data.formes.nom
              : lang === "en"
              ? data.formes.nom_en
              : data.formes.nom_ar;
        }
        if (data.actions) {
          var nomAction =
            lang === "fr"
              ? data.actions.nom_fr
              : lang === "en"
              ? data.actions.nom_en
              : data.actions.nom_ar;
        }

        //notificateur
        var nomNotificateur = data.users
          ? data.users.nom + " " + data.users.prenom
          : data.passagers.nom + " " + data.passagers.prenom;

        //date
        var date = new Date(
          new Date(data.patients.createdAt).getTime() -
            new Date(data.patients.createdAt).getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 10);

        var sexe =
          data.patients.sexe === 1
            ? t("Declaration.man")
            : data.patients.sexe === 2
            ? t("Declaration.woman")
            : t("Declaration.other");

        var age =
          data.patients.age === 1
            ? data.patients.dateNaissance
            : data.patients.age === 2
            ? data.patients.agePatient
            : nomAge;

        var effet = data.effet;
        var therapeutique = data.therapeutique;
        var poid = data.patients.poid;
        var taille = data.patients.taille;
        var allergie = data.patients.allergie;
        var numero = data.patients.numero;
        var posologie = data.posologie;
        var dateDebutAdmin = data.dateDebutAdmin;
        var dateFinAdmin = data.dateFinAdmin;
        var dateDebut = data.dateDebut;
        var dateFin = data.dateFin;
        var information = data.information;
        var complementaires = data.complementaires;
        var initiales = data.patients.initiales;
        var grave =
          data.grave === 1 ? t("Declaration.yes") : t("Declaration.no");
        var hospitalisation =
          data.hospitalisation === 1
            ? t("Declaration.yes")
            : t("Declaration.no");
        var pronostic =
          data.pronostic === 1 ? t("Declaration.yes") : t("Declaration.no");
        var deces =
          data.deces === 1 ? t("Declaration.yes") : t("Declaration.no");
        var incapacite =
          data.incapacite === 1 ? t("Declaration.yes") : t("Declaration.no");
        var anomalie =
          data.anomalie === 1 ? t("Declaration.yes") : t("Declaration.no");
        var autre =
          data.autre === 1 ? t("Declaration.yes") : t("Declaration.no");
        var traites =
          data.traites === 1
            ? t("Declaration.traites_yes")
            : data.traites === 2
            ? t("Declaration.traites_no")
            : t("Declaration.traites_inc");
        var evolution =
          data.evolution === 1
            ? t("Declaration.evolution_txt1")
            : data.evolution === 2
            ? t("Declaration.evolution_txt2")
            : data.evolution === 3
            ? t("Declaration.evolution_txt3")
            : data.evolution === 4
            ? t("Declaration.evolution_txt4")
            : data.evolution === 5
            ? t("Declaration.evolution_txt5")
            : data.evolution === 6
            ? t("Declaration.evolution_txt6")
            : "";
        var survenus =
          data.survenus === 1
            ? t("Declaration.survenus_txt1")
            : data.survenus === 2
            ? t("Declaration.survenus_txt2")
            : data.survenus === 3
            ? t("Declaration.survenus_txt3")
            : data.survenus === 4
            ? t("Declaration.survenus_txt4")
            : data.survenus === 5
            ? t("Declaration.survenus_txt5")
            : data.survenus === 6
            ? t("Declaration.survenus_txt6")
            : "";

        var obj = {};
        obj[0] = nomNotificateur;
        obj[1] = date;
        obj[2] = initiales;
        obj[3] = sexe;
        obj[4] = age;
        obj[5] = nomInd;
        obj[6] = poid;
        obj[7] = taille;
        obj[8] = allergie;
        obj[9] = nomMed;
        obj[10] = numero;
        obj[11] = posologie;
        obj[12] = nomForme;
        obj[13] = nomAction;
        obj[14] = therapeutique;
        obj[15] = nomVoix;
        obj[16] = dateDebutAdmin;
        obj[17] = dateFinAdmin;
        obj[18] = effet;
        obj[19] = dateDebut;
        obj[20] = dateFin;
        obj[21] = information;
        obj[22] = complementaires;
        obj[23] = grave;
        obj[24] = hospitalisation;
        obj[25] = pronostic;
        obj[26] = deces;
        obj[27] = incapacite;
        obj[28] = anomalie;
        obj[29] = autre;
        obj[30] = traites;
        obj[31] = evolution;
        obj[32] = survenus;
        array.push(obj);
      });
      exportToExcel(array);
    },
    [dispatch, exportToExcel, id]
  );

  return (
    <>
      {alert}
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="6" sm="6" xs="12">
            <Button
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="success"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              {t("Declaration.add")}
            </Button>
          </Col>
          <Col md="6" sm="6" xs="12">
            {(id_role === 1 || id_role === 3) && entities.length !== 0 ? (
              <Button
                className="btn-wd  mr-1 float-right"
                type="button"
                variant="success"
                onClick={() => {
                  getDetail(entitiesExcel);
                }}
              >
                <span className="btn-label">
                  <i class="fas fa-download"></i>
                </span>
                {t("Declaration.export")}
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="title">{t("Declaration.list")}</h4>
            <Card>
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListDeclaration;
